import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { record, Replayer  } from 'rrweb';
import { BehaviorSubject, catchError, Observable } from 'rxjs';

@Injectable()
export class RecordingService {
  
  constructor(private http: HttpClient) { }

  private eventsFileName: string;
  public setFileName(fileName: string) {
    this.eventsFileName = fileName;
  }
  private events: any[] = [];
  private stopFn: () => void;

  startRecording() {
    this.stopFn = record({
      emit: (event) => {
        this.events.push(event);
      },
      blockClass: 'recording-buttons',
      sampling: {
        // throttle mousemove events to once every 100ms
        mousemove: 100,
        // throttle mouse interaction events (click, dblclick, etc.)
        mouseInteraction: {
          click: true,
          dblclick: true,
          mousedown: true,
          mouseup: true,
          mousemove: false, // already throttled above
          mouseover: false,
          mouseout: false,
          mouseenter: false,
          mouseleave: false,
        },
        // ignore other event types by setting their throttle to a high value
        scroll: 300,
        input: 'last', // only keep the latest input event
      },
      

    });
  }


  stopRecording() {
    if (this.stopFn) {
      this.stopFn();
    }
  }

  getEvents() {
    return this.events;
  }

  replayEvents(events: any[], container: HTMLElement, onReplayFinish: () => void) {
    // Clear the container
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    
    const replayer = new Replayer(events, {
      root: container,
      mouseTail: false, // Disable mouse tail for simplicity
      skipInactive: true // Skip inactive parts to make replay smoother

      
    });
    replayer.on('finish', onReplayFinish);
    replayer.play();
  }

  downloadEvents() {
    const blob = new Blob([JSON.stringify(this.events)], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.eventsFileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  public loadEventsFromFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.events = JSON.parse(reader.result as string);
    };
    reader.readAsText(file);
  }


  

  public getFileContentsFromFile(fileName: string): Observable<any[]> {
    return this.http.get<any[]>(`assets/training/${fileName}.json`).pipe();
    // .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  HandleError(err)
  {
    alert(err);
  }




}
