import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectableItem } from '@app/shared/item-selector/selectable-item';
import { ProcedureCode } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { proceduresService } from '@app/_services/procedures.service';
import { map, Observable, of, tap } from 'rxjs';
import { EditProcedureCodeComponent } from './edit-procedure-code/edit-procedure-code.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-procedure-codes',
  templateUrl: './manage-procedure-codes.component.html',
  styleUrls: ['./manage-procedure-codes.component.scss']
})
export class ManageProcedureCodesComponent implements OnInit, OnDestroy {

  masterSet$: Observable<SelectableItem[]> = of([]);
  activeIcdCodes$: Observable<SelectableItem[]> = of([]);
  masterBackupData: ProcedureCode[] = [];
  loadingMasterSets = true;
  constructor(
    private webApiInterface: ApiInterfaceService, 
    private apiInterface: ApiInterfaceService, 
    private pcService: proceduresService, 
    public dialog: MatDialog, 
    private activatedRoute: ActivatedRoute
  ) {

    //this.specialityCode = this.activatedRoute.snapshot.queryParams['specialtycode'] ;

    this.activatedRoute.queryParams.subscribe(params => {
      
      if (params['specialtycode'])
      {
        this.specialityCode = params['specialtycode'] ;        
      }
      else
      {
        this.specialityCode = null;
      }

      
      this.fetchProcedureCodeMasterSet()
    });



  }

  specialityCode: string;
  ngOnInit(): void {
    this.fetchActiveProcedureCodes();
  }

  enableCount = 0;

  public activate(items: SelectableItem[]) {
    items.forEach(item => {
      const tSave = this.masterBackupData.find(z => z.id === item.code);
      this.apiInterface.createEntity<ProcedureCode>(new ProcedureCode(), tSave).subscribe();
    })
  }

  public deactivate(items: SelectableItem[]) {
    items.forEach(item => {
      this.apiInterface.deleteEntity<ProcedureCode>(new ProcedureCode(), item.code).subscribe();
    })
  }

  private fetchActiveProcedureCodes(): void {
    this.activeIcdCodes$ = this.webApiInterface.getEntities<ProcedureCode>(new ProcedureCode()).pipe(
      tap(data =>  {this.masterBackupData = [...data, ...this.masterBackupData], this.loadingMasterSets = false }),
      map(data => data.sort((a, b) => a.name > b.name ? 1 : -1)),
      map(data => data.map(icd => {
        return {
          code: icd.id,
          name: icd.name,
          description: icd.description,
          price: icd.units
        } as SelectableItem;
      })));
  }

  public launchCreate($event): void {
    const dialogRef = this.dialog.open(EditProcedureCodeComponent, {
      data: new ProcedureCode()
    });

    dialogRef.afterClosed().subscribe((result: ProcedureCode) => {
      if (result) {
        this.apiInterface.createEntity(new ProcedureCode(), result).subscribe((createdEntity) => {
          this.activeIcdCodes$ = this.activeIcdCodes$.pipe(map(data => {
           data.unshift({ code : createdEntity.id,name : createdEntity.name, description : createdEntity.description,} as SelectableItem)
            return data;
          }))
        })
      }
    })
  }

  public launchEdit(item: SelectableItem): void {
    if (item && item.code) {
      const editItem = this.masterBackupData.find(x => x.name === item.code);
      if (editItem) {
        const dialogRef = this.dialog.open(EditProcedureCodeComponent, {
          data: editItem
        });

        dialogRef.afterClosed().subscribe((result: ProcedureCode) => {
          if (result) {
            this.apiInterface.updateEntity(new ProcedureCode(), result).subscribe(() => {
              this.activeIcdCodes$ = this.activeIcdCodes$.pipe(map(data => {
                const index = data.findIndex(x => x.code === item.code);
                data[index].description = result.description;
                return data;
              }))
            })
          }
        })
      }
    }
  }
  public fetchProcedureCodeMasterSet(): void {
    this.masterSet$ = this.pcService.fetchAllProcedureCodes$(this.specialityCode).pipe(
      map(data => data.filter(item => this.masterBackupData.map(z => z.name).indexOf(item.name) < 0)),
      tap(data => this.masterBackupData = [...data, ...this.masterBackupData]),
      map(data => data.sort((a, b) => a.name > b.name ? 1 : -1)),
      map(data => data.map(icd => {
        return {
          code: icd.id,
          name: icd.name,
          description: icd.description,
          price: icd.units
        } as SelectableItem;
      })));
  }

  ngOnDestroy ()
  {
    // Edits in this page will effect preload data so force reload
    this.apiInterface.forcePreLoadDataReload();
  }
}
