import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Patient } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit {

  @Output()
  public onPatientSelected = new EventEmitter<Patient>();
  public options: { label: string, id: string }[] = [];

  private patients: Patient[] = [];

  constructor(private apiInterfaceService: ApiInterfaceService) { }

  ngOnInit(): void {
  }

  public doSearch = (term: string) => this.search(term);

  public onSelection = (selectedId: string) => this.onPatientSelected.emit(this.patients.find(p => p.id === selectedId));

  private search(searchString: string): void {
    if (searchString) {
      this.apiInterfaceService.getPatientsByWildCardWebGuiApiCall(searchString).pipe(first()).subscribe(patients => {
        this.patients = patients;
        this.options = this.patients.map(p => {
          return { label: `${p.firstName} ${p.lastName} - ${p.patientNo}`, id: p.id }
        });
      });
    }
  }
}
