
// combo-box.component.ts
import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldDescriber, IGlowWhenBranchActivated, IReturnValue } from '@app/_alchemint/alchemint_composite_requests';
import { ICanBranchLogic, shouldDisplayDueToBranchLogic } from '@app/branch-logic-functions';
import { FormFieldDescriberExtended } from '@app/external-form/external-form.component';


@Component({
  selector: 'app-multi-line',
  templateUrl: './multi-line.component.html',
  styleUrls: ['./multi-line.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiLineComponent),
      multi: true
    }
  ]
})
export class MultiLineComponent implements OnInit, ControlValueAccessor, IReturnValue, IGlowWhenBranchActivated, ICanBranchLogic {
  
  constructor(public renderer: Renderer2, public  el: ElementRef) {}
  public glow: boolean;

  @ViewChild('textAreaEditor') textAreaEditorref: ElementRef;

  
  
  @Input() isExternalRqMode: boolean = false;
  
  @Input() id: string;
  @Input() readOnly: boolean = false;
  @Input() classing: string;
  @Input() style: string;

  @Input() textAreaStyle: string;

  public formFieldDescriberExtended: FormFieldDescriberExtended;
  public defferredLoadComponents: [string, any, IReturnValue][];



  shouldDisplay(): boolean
  {
    var ret: boolean;
    ret =  shouldDisplayDueToBranchLogic(this.formFieldDescriberExtended , this.defferredLoadComponents, this.el, this.renderer);
    return ret;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  public loadingImage: boolean = false;
  public brokenImage: boolean = false;

  public _textData: string;
  
  public set textData(val : string)
  {
    this._textData = val;
    this.onChange(val);
    this.onTouched();
  }
  public get textData() : string
  {
    return this._textData;
  }

  ngOnInit ()
  {

  }

  writeValue(val: string): void {
    this.setComponentValue (val);
  }

  setComponentValue(val: string)
  {
    this.textData = val;
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public get value(): any {
    return this.textData;

  }
  public set value(val: any) {
    this.setComponentValue(val);
  }

  @Input() backColor: string = 'white';
  
  public get colorStyle(): string {
    return `background-color: ${this.backColor};`;
  }


  ngAfterViewInit(): void {
  
  
  }



  }
  


