import { Component } from '@angular/core';
import { MultiFactorAuthenticationService } from './muti-factor-auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService as ToastService } from 'fd-alerts';
import { DomSanitizer } from '@angular/platform-browser';
import { OneTimePassowrdFormComponent } from './one-time-passowrd-form/one-time-passowrd-form.component';
import { AuthenticationService } from '@app/_services';
import { MfaRecoverySettingsComponent } from './mfa-recovery-settings/mfa-recovery-settings.component';
import { WindowCloseButtonModule } from '@app/appcommonuicomponents/window-close-button/window-close-button.module';

export const COUNTRY_CODES = [
  { code: '+1', name: 'US' },
  { code: '+1', name: 'CA' },
  { code: '+44', name: 'UK' },
  { code: '+61', name: 'AU' },
  { code: '+27', name: 'ZA' },
  { code: '+91', name: 'IN' },
  { code: '+55', name: 'BR' },
  { code: '+86', name: 'CN' },
  { code: '+33', name: 'FR' },
  { code: '+49', name: 'DE' },
  { code: '+81', name: 'JP' },
];

@Component({
  selector: 'app-two-factor-popup',
  templateUrl: './mult-factor-auth.component.html',
  styleUrls: ['./mult-factor-auth.component.scss'],
  standalone: true,
  imports: [
    OneTimePassowrdFormComponent,
    MfaRecoverySettingsComponent,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    WindowCloseButtonModule
  ]
})
export class MultiFactorAuthComponent {
  qrCodeData: string;
  isTwoFactorEnabled: boolean;
  twoFactorCode: string;
  confirmOTP = false;
  useEmail = false;
  useSMS = false;
  useWhatsApp = false;
  useAuthApp = false;
  showQrCodeImage = false;
  phoneNumber = "";
  countryCodes = COUNTRY_CODES;
  selectedCountryCode = null;
  phoneForm: FormGroup;
  emailFormControl = new FormControl('', [Validators.required])
  phoneNumberPattern = /^\+27\d{9}$/;
  otpConfirmationErrorMessage?: string;
  configure2FARequestErrorMessage?: string;
  qrCodeImagePath: any;
  requestUserMfaRecoveryDetails = false;

  optCode?: number;
  currentRecoveryEmail?: string;
  currentRecoveryPhone?: string;

  constructor(private mfaService: MultiFactorAuthenticationService, private sharedService: AlchemintSharedService, private fb: FormBuilder, public activeDialog: MatDialogRef<MultiFactorAuthComponent>
    , private toastService: ToastService, private authService: AuthenticationService, private sanitizer: DomSanitizer) {
    this.phoneForm = this.fb.group({
      countryCode: ['', Validators.pattern(/^\+27/)], // South African format
      phoneNumber: ['', Validators.pattern(/^\d{9}$/)]
    });

    this.authService.GetStoredUserAsync().then((u) => {
      if (u && u.twoFactorAuthSettings?.enabled) {
        this.on2FAchannelToggled(u.twoFactorAuthSettings.channel as any, true)
      }
    })
  }

  enable2FAWithEmail(): void {
    const email = this.emailFormControl.value
    this.mfaService.enable2FAWithEmail(email).subscribe({
      next: (resp) => {
        this.confirmOTP = true;
      },
      error: (error) => {
        this.configure2FARequestErrorMessage = error;
      }
    });
  }

  enable2FAWithSMS(): void {
    const ctrl = this.phoneForm.controls;
    const phoneNumber = `${ctrl['countryCode'].value}${ctrl['phoneNumber'].value}`
    this.mfaService.enable2FAWithSMS(phoneNumber).subscribe({
      next: (resp) => {
        this.confirmOTP = true;
      },
      error: (error) => {
        this.configure2FARequestErrorMessage = error;
      }
    });
  }

  enable2FAWithApp(): void {
    this.mfaService.enable2FAWithApp().subscribe({
      next: (resp) => {
        this.useAuthApp = true;
        if (resp && resp['twoFactorAuthImageScan']) {
          const qrCodeImage = resp['twoFactorAuthImageScan'];
          this.qrCodeImagePath = this.sanitizer.bypassSecurityTrustResourceUrl(qrCodeImage);
        }
      },
      error: (error) => {
        this.configure2FARequestErrorMessage = error;
      }
    });
  }

  on2FAchannelToggled(chanel: 'SMS' | 'EMAIL' | 'WHATSAPP' | 'APP', value: boolean) {
    switch (chanel) {
      case 'SMS': {
        this.useAuthApp = this.useEmail = false;
        this.useSMS = value;
        break;
      }
      case 'EMAIL': {
        this.useAuthApp = this.useSMS = false;
        this.useEmail = value;
        break;
      }
      case 'WHATSAPP': {
        break;
      }
      case 'APP': {
        this.useSMS = this.useEmail = false;
        this.useAuthApp = value;
        this.enable2FAWithApp();
        break;
      }
    }
  }

  verifyAndComplete(otp: number): void {
    this.mfaService.VerifyUserOtp(otp).subscribe({
      next: (response) => {
        if (response.success && response.success === true) {
          this.optCode = otp;
          this.currentRecoveryEmail = response.recoveryEmail || null
          this.currentRecoveryPhone = response.recoveryPhone || null
          this.requestUserMfaRecoveryDetails = true;
        } else {
          alert("Failed to complete your request");
        }
      },
      error: (error) => {
        this.otpConfirmationErrorMessage = error;
        console.error('Failed to enable 2FA', error);
      }
    });
  }

  submit(optValue: number) {
    this.verifyAndComplete(optValue);
  }

  handleDigitInput(event: KeyboardEvent, prevInput: HTMLElement, nextInput: HTMLElement) {
    if (event.key === 'Backspace') {
      prevInput.focus();
    } else {
      const regex = /^\d$/;
      if (regex.test(event.key) && nextInput) {
        nextInput.focus();
      }
    }
  }

  disableTwoFactorAuth(): void {
    this.mfaService.disableTwoFactorAuth().subscribe((response) => {
      this.activeDialog.close();
    });
  }
}
