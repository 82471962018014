import { Directive } from '@angular/core';
import { ElementRef } from '@angular/core';

import { Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';


enum eHtmlElemTypes
{
  Unknown,
  Anchor,
  Label,
  Div
}

@Directive({
  selector: '[appNonMobileFeature]'
})
export class NonMobileFeatureDirective  {


  constructor(private el: ElementRef, private deviceDetectorService : DeviceDetectorService, ) {
  }
  
  isNonMobileFeature : boolean = false;

  @Input() set appNonMobileFeature (value : boolean) 
  {
      this.isNonMobileFeature = value;
  }
  
  private htmlElem : HTMLElement;
  ngOnInit() {
    this.htmlElem = <HTMLElement> this.el.nativeElement; 
    this.setVisibility(this.htmlElem);
  }


  private setVisibility (hTMLElement : HTMLElement) 
  {
    var isMobileDevice = this.deviceDetectorService.isMobile();
    if (isMobileDevice && this.isNonMobileFeature)
    {
      hTMLElement.hidden = true;
    }
  }



}
