
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Patient } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { PatientService } from '@app/_services/patient.service';

import { WindowCloseButtonComponent } from '@app/appcommonuicomponents/window-close-button/window-close-button.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-patient-relink-component',
  templateUrl: './patient-relink-component.component.html',
  styleUrls: ['./patient-relink-component.component.scss']
})
export class PatientRelinkComponentComponent implements OnInit {

  patientRelinkSelection : PatientRelinkSelection;
  selectedPatient: Patient;
  alsoDeleteUnlinkedPatient : boolean = false;
  moveFilesToTargetPatient: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PatientRelinkComponentComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private patientService: PatientService, 
    private apiInterfaceService: ApiInterfaceService) { }
  @Output()
  public onPatientSelected = new EventEmitter<Patient>();
  public options: { label: string, id: string }[] = [];

  private patients: Patient[] = [];
  ngOnInit(): void {
  
  
  
  
  }

  public userCancelled(): void {
    this.patientRelinkSelection = null;
    this.dialogRef.close(null);
  }

  public relinkPatient(): void {
    this.patientRelinkSelection = new PatientRelinkSelection(this.selectedPatient, this.alsoDeleteUnlinkedPatient, this.moveFilesToTargetPatient);
    this.dialogRef.close(this.patientRelinkSelection);
  }

  public doSearch = (term: string) => this.search(term);

  public onSelection = (selectedId: string) => 
    {
    this.selectedPatient = this.patients.find(p => p.id === selectedId);
    this.onPatientSelected?.emit(this.selectedPatient);
    }
  private search(searchString: string): void {
    if (searchString) {
      this.apiInterfaceService.getPatientsByWildCardWebGuiApiCall(searchString).pipe(first()).subscribe(patients => {
        this.patients = patients;
        this.options = this.patients.map(p => {
          return { label: `${p.firstName} ${p.lastName} - ${p.patientNo}`, id: p.id }
        });
      });
    }
  }
}

export class PatientRelinkSelection
{
  constructor (public CorrectPatient : Patient, public DeleteIncorrectPatient : boolean, public MoveFilesToTargetPatient: boolean	)
  {

  }

}
