<h1 class="dialog-form-heading" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Link Appointment to Patient
</h1>
  
<div class="dialog-container">
    <div class="explanation">
        <p class="explain-content">This feature is used to link an appointment to a different patient. This is useful when an appointment in iHealth triggered the creation of a patient in Alchemed but when the patient already existed. This happens when the match criteria do not match (patient with the same first name, last name, and cell phone number). All Files and Forms from the source patient will also be moved to the chosen patient.</p>
    </div>
    <div class="search-patient">
        <p>Search for the correct patient to link consult to</p>
        <app-input-popup (onChange)="doSearch($event)" (onItemSelection)="onSelection($event)" [dropDownOptions]="options"></app-input-popup>
    </div>
    <div class="checkboxes">
        <!-- <mat-checkbox [(ngModel)]="moveFilesToTargetPatient" [checked]="moveFilesToTargetPatient">
            Move Patient files to selected patient (Recommended)
        </mat-checkbox> -->
        <mat-checkbox [(ngModel)]="alsoDeleteUnlinkedPatient" [checked]="alsoDeleteUnlinkedPatient">
            Also delete the patient that is being unlinked
        </mat-checkbox>
    </div>
    <div class="actions">
        <button [disabled]="!selectedPatient" class="btn btn-primary" (click)="relinkPatient()">Link</button>
        <button class="btn btn-secondary" (click)="userCancelled()">Cancel</button>
    </div>
</div>


<!-- <div>

    <div class="link-explanation">
        This feature is used to link a consult to a different patient. This is useful when a appointment in iHealth triggered the creation of a Patient in Alchemed but when the patient already existed. 
        This happens when the match criteria do not match (Patient same First name, Last name and cell phone number)
    </div>


    <p style="margin: 15px;">
       Search for correct patient to link conuslt to
    </p>
     
     <div style="height: 300px;">
        <app-input-popup (onChange)="doSearch($event)"  (onItemSelection)="onSelection($event)" [dropDownOptions]="options"></app-input-popup>

    </div>

    <div>
        <mat-checkbox [(ngModel)]="moveFilesToTargetPatient" [checked]="moveFilesToTargetPatient">
            Move Patient files to selected patient (Recommended)
        </mat-checkbox>
    </div>

    <div>
        <mat-checkbox [(ngModel)]="alsoDeleteUnlinkedPatient" [checked]="alsoDeleteUnlinkedPatient">
            Also delete the patient that is being unlinked
        </mat-checkbox>
    </div>

    <div>
        <button [disabled]="!selectedPatient" style="height: 40px;" class="btn btn-primary" (click)="relinkPatient()">Link</button>
        <button style="height: 40px;" class="btn btn-secondary" (click)="userCancelled()">Cancel</button>
    </div>

    <div>

    </div>

</div> -->