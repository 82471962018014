import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[appDoneButton]'
})
export class DoneButtonDirective {

  constructor(private router: Router, private route: ActivatedRoute, private el: ElementRef) {}

  ngOnInit(): void {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (returnUrl) {
      
      this.el.nativeElement.addEventListener('click', () => {
        this.router.navigateByUrl(returnUrl);
      });
      
      

    }
    else
    {
      this.el.nativeElement.hidden = true;
    }

  }
}





