import { Component, OnInit , Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions, MatDialogRef} from '@angular/material/dialog'; 
import { Artifact, ArtiForm, IAlchemintEntity } from '@app/_alchemint/alchemint_dm';
import { UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './user-input-text.component.html',
  styleUrls: ['./user-input-text.component.scss']
})
export class UserInputTextComponent implements OnInit {


  
  public selectedEntityId : string;
  public selectedEntity : string;
  public entType : any;
  public userInputValue : string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public userSeletInfo: UserSeletInfo, public dialogRef: MatDialogRef<UserInputTextComponent>) 
  { 
    //this.entities = userSeletInfo.entities;
    this.userInputValue = userSeletInfo.default;
  }

  ngOnInit(): void {
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }
    
  changeVal (val : any)
  {
    this.userInputValue = val.value;
  }
  closeDialog() {
    if (this.userInputValue) { // Checks if there is some input value
        this.dialogRef.close(this.userInputValue); // Closes the dialog and passes the input value back
    }
}


}
