import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import { ProgressService } from '@app/_services/progress.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class ProgressBatHttpInterceptor implements HttpInterceptor {
  obs: Observable<number>; // PROBLEM: How to expose this as hot Obeservable ?
  private count = 0;

  constructor(private progressService : ProgressService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    this.progressService.isBusy = true;
    return next.handle(req).pipe(finalize(
      () => {
        this.progressService.isBusy = false;
      }
    ));



    //this.count++;   // increment the count here
    this.progressService.isBusy = true;
    const observable = next.handle(req);
    //observable.subscribe(
      // () => {   }, 
      // () => {   }, 
      // () => {  this.progressService.isBusy = false; });
    return observable;
 
 
  }
}