import { EventEmitter, Injectable, Output } from '@angular/core';
import { Artifact } from './_alchemint/alchemint_dm';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  @Output() fullScreenRequested = new EventEmitter();
  @Output() normalScreenRequested = new EventEmitter();
  @Output() cameraScreenRequested = new EventEmitter();

  constructor() { }


  public fullScreenMode : boolean = false;

  public cameraMode : boolean = false;

  public requestCameraScreen (fullScreenMode: boolean = true) : void
  {
    this.fullScreenMode = fullScreenMode;
    this.cameraMode = true;
    //this.cameraScreenRequested?.emit();
  }

  public requestNormalScreen () : void
  {
    this.fullScreenMode = false;
    this.cameraMode = false;
    //this.normalScreenRequested?.emit();
  }

  public requestFullScreen () : void
  {
    this.cameraMode = false;
    this.fullScreenMode = true;
    //this.fullScreenRequested?.emit();
  }




  artifactFormatedSize (ent : Artifact) : string{
    try {
      var val : number = (Math.round ((ent?.fileSize ) / 1024));
      if (val > 1024)
      {
        val = (val / 1024);
       
        return val?.toFixed(1) + ' MB';
      }
      else
      {
        val = Math.round (val)
        return val?.toString() + ' KB';
      }
        
    } catch (error) {
      console.error(error);      
      return "";
    }
    
  }




  
}
