import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MultiFactorAuthComponent } from '../mult-factor-auth.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MultiFactorAuthenticationService } from '../muti-factor-auth.service';
import { AuthenticationService } from '@app/_services';
import { AlertService as ToastService } from 'fd-alerts';
@Component({
  standalone: true,
  selector: 'mfa-recovery-settings',
  templateUrl: 'mfa-recovery-settings.component.html',
  styleUrls: ['mfa-recovery-settings.component.scss'],
  imports: [
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
  ],
})
export class MfaRecoverySettingsComponent {

  @Input() set otpCode(code: number) {
    this.mfaRecoveryForm.patchValue({ otp: code });
  }
  @Input() set currentPhoneNumber(phone: string) {
    this.mfaRecoveryForm.patchValue({ recoveryPhoneNumber: phone });
  }

  @Input() set currentEmail(email: string) {
    this.mfaRecoveryForm.patchValue({ recoveryEmail: email });
  }

  mfaRecoveryForm = new FormGroup({
    recoveryEmail: new FormControl('', [Validators.required, Validators.email]),
    recoveryPhoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[+][0-9]{10,15}$/)
    ]),
    otp: new FormControl(0, [Validators.required, Validators.minLength(6)])
  });

  constructor(private mfaService: MultiFactorAuthenticationService
    , private toastService: ToastService
    , private authService: AuthenticationService
    , public activeDialog: MatDialogRef<MultiFactorAuthComponent>) { }

  onSubmit() {
    if (this.mfaRecoveryForm.valid) {
      const val = this.mfaRecoveryForm.value;
      this.mfaService.completeMfaSetup({
        recoveryEmail: val.recoveryEmail || null,
        recoveryPhoneNumber: val.recoveryPhoneNumber || null,
        code: val.otp
      }).subscribe({
        error: error => console.log(error),
        next: (response: { activeMFAChannel: string, user: string }) => {
          this.toastService.showInfo("Two factor authentication configured seccessfully");
          this.authService.updateUser2FaChannel(response.activeMFAChannel, response.user, true);
          this.activeDialog.close();
        }
      })

    } else {
      console.log('Form is not valid');
    }
  }
}
