<div class="input-dropdown-container" style="height: 41px;">
    <div class="input-with-btn">
        <input class="search-box" [(ngModel)]="inputText"  #searchInput
            (keydown.enter)="showDropDownOptions = !showDropDownOptions; onChange.emit(inputText)" (keyup)="filterOptions()">
        <button  class="btn btn-primary" id="basic-addon1" (click)="showDropDownOptions = !showDropDownOptions; onChange.emit(inputText)">
            <svg-icon src="assets/svgs/search_white_24dp.svg" ></svg-icon>
        </button>
    </div>
    <div class="input-dropdown" #dropDown [hidden]="!showDropDownOptions">
        <div class="input-dropdown-item-container">
            <div (click)="showDropDownOptions=false;onItemSelection.emit(option.id); inputText = option.label" class="dropdown-item"
                *ngFor="let option of options">{{option.label}}</div>
        </div>
    </div>
</div>
