import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { PopupMenuItemComponent } from './popup-menu-item/popup-menu-item.component';

@NgModule({
  declarations: [
    PopupMenuComponent,
    PopupMenuItemComponent,
  ],
  exports: [
    PopupMenuComponent,
    PopupMenuItemComponent,
    FormsModule,
  ],
  imports: [
    CommonModule,
    MatMenuModule
  ],

})
export class MenuPopupModule { }
