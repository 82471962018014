<div class="card mt-4" >
    <h4 class="card-header">Details</h4>
    <div class="card-body">
        <!-- <h6>Patients</h6> -->
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <ul *ngIf="entity">
            <li> {{entity.id}} </li>
            <li> {{entity.patientNo}} </li>
            <li> {{entity.title}} {{entity.firstName}} {{entity.lastName}} </li>
        </ul>
    </div>
</div>
