
import { Routes, RouterModule } from '@angular/router';
import { EntityrootComponent } from './entityroot/entityroot.component';
import { WhiteLabellingService } from '@app/_alchemint/white-labelling.service';
import { AuthGuard } from './_helpers/auth.guard';
import { UserManagementComponent } from './user-management/user-management.component';
import { ContactManagerComponent } from './contact-manager/contact-manager.component';
import { ChangePasswordComponent } from './user-management/change-password/change-password.component';
import { ChangeEmailComponent } from './user-management/change-email/change-email.component';
import { ConfirmDeleteUserComponent } from './user-management/confirm-delete-user/confirm-delete-user.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { IcdManagerComponent } from './icd-manager/icd-manager.component';
import { ManageProcedureCodesComponent } from './manage-procedure-codes/manage-procedure-codes.component';
import { ManageMaterialCodesComponent } from './manage-material-codes/manage-material-codes.component';
import { ManageConsultTypesComponent } from './manage-consult-types/manage-consult-types.component';
import { ManageSitesComponent } from './manage-sites/manage-sites.component';
import { ManageContractsComponent } from './manage-contracts/manage-contracts.component';
// import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { AuthGuardAdminFunctions } from './_helpers/auth.guard.adminfunctions';

import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { PatientRelinkComponentComponent } from './patient-relink-component/patient-relink-component.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompleteAdmisionComponent } from './complete-admision/complete-admision.component';
import { TemplateSelectorComponent } from './form-builder/template-selector/template-selector.component';
import { GenericObjectEditorComponent } from './generic-object-editor/generic-object-editor.component';
import { PatientSearchComponent } from './shared/patient-search/patient-search.component';
import { ManageTemplatesComponent } from './manage-templates/manage-templates.component';
import { TheatreSlateComponent } from './theatre-slate/theatre-slate.component';
import { MultiLineRoutingModule } from './multi-line/multi-line-routing.module';
import { ElectronGuard } from './_helpers/electron.guard';
import { boolean } from 'mathjs';
import { AuthGuardAdvancedSearch } from './_helpers/auth.guard.advancedsearch';
import { HtmlFileViewerRoutingModule } from './html-file-viewer/html-file-viewer-routing.module';
import { AuthGuardBackoffice, AuthGuardSuperUsers, AuthGuardUseReports } from './auth-backoffice.guard';
import { NgModule } from '@angular/core';
// import { VideoCallComponent } from './video-call/video-call.component';


//import { WysiwygEditorComponent } from './wysiwyg-editor/wysiwyg-editor.component';
const electronroutes: Routes = [

    { path: 'devrobot', loadChildren: () => import('./simulator-interface/simulator-interface-routing.module').then(m => m.SimulatorInterfaceRoutingModule), canActivate: [ElectronGuard] },
    { path: 'backups', loadChildren: () => import('./backup-config/backup-config-routing.module').then(m => m.BackupConfigRoutingModule), canActivate: [ElectronGuard] },
    { path: 'login', loadChildren: () => import('./login/login-module.module').then(m => m.LoginModuleModule), canActivate: [AuthGuard] },
    // ... your other routes
    { path: '**', redirectTo: '/backups' } // Redirect any other route to 'backups'
];

var routes: Routes;

var whiteLabellingService: WhiteLabellingService = new WhiteLabellingService();


if (whiteLabellingService.isAlchemint() == false) {
    routes = [
        //{ path: '', component: ContentViewComponent,  },

        { path: 'ts', loadChildren: () => import('./theatre-slate/theatre-slate.module').then(m => m.TheatreSlateModule), canActivate: [AuthGuard] },

        { path: 'addtoduiary', loadChildren: () => import('./add-to-diary/add-to-diary-routing.module').then(m => m.AddToDiaryRoutingModule), canActivate: [AuthGuard] },
        { path: 'completeadmision', loadChildren: () => import('./complete-admision/complete-admission-routing.module').then(m => m.CompleteAdmissionRoutingModule), canActivate: [AuthGuard] },
        // { path: 'completeadmision', component: CompleteAdmisionComponent , loadChildren: () => import('./complete-admision/complete-admission.module').then(m => m.CompleteAdmissionModule), canActivate: [AuthGuard] },


        // { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule), canActivate: [AuthGuard] },

        { path: 'reporting', loadChildren: () => import('./reporting/reporting-routing.module').then(m => m.ReportingRoutingModule), canActivate: [AuthGuard] },

        { path: 'backoffice', loadChildren: () => import('./back-office/back-office-routing.module').then(m => m.BackOfficeRoutingModule), canActivate: [AuthGuard] },

        { path: 'backofficeclient', loadChildren: () => import('./back-office-client/back-office-client-routing.module').then(m => m.BackOfficeClientRoutingModule), canActivate: [AuthGuard] },



        { path: 'backofficereporting', loadChildren: () => import('./reporting/reporting-routing.module').then(m => m.ReportingRoutingModule), canActivate: [AuthGuard, AuthGuardBackoffice, AuthGuardUseReports] },
        { path: 'devopsreporting', loadChildren: () => import('./reporting/reporting-routing.module').then(m => m.ReportingRoutingModule), canActivate: [AuthGuard] },
        { path: 'commandcentre', loadChildren: () => import('./command-centre/command-centre-routing.module').then(m => m.CommandCentreRoutingModule), canActivate: [AuthGuard, AuthGuardBackoffice] },
        { path: 'togglebutton', loadChildren: () => import('./toggle-button/toggle-button-routing.module').then(m => m.ToggleButtonRoutingModule), canActivate: [AuthGuard] },
        { path: 'surgicaldashboard', loadChildren: () => import('./surgical-management-dashboard/surgical-management-dashboard-routing.module').then(m => m.SurgicalManagementDashboardRoutingModule), canActivate: [AuthGuard] },
        { path: 'devrobot', loadChildren: () => import('./simulator-interface/simulator-interface-routing.module').then(m => m.SimulatorInterfaceRoutingModule), canActivate: [AuthGuardSuperUsers] },


        //@INSERTIONPOINT@

{ path: 'task-management', loadChildren: () => import('./task-management/task-management-routing.module').then(m => m.TaskManagementRoutingModule), canActivate: [AuthGuard] },






        { path: 'sketch-pad-pop-out', loadChildren: () => import('./sketch-pad-pop-out/sketch-pad-pop-out.routing.module').then(m => m.SketchPadPopOutRoutingModule), canActivate: [AuthGuard] },

        { path: 'sketch-pad-pop-out', loadChildren: () => import('./sketch-pad-pop-out/sketch-pad-pop-out.routing.module').then(m => m.SketchPadPopOutRoutingModule), canActivate: [AuthGuard] },





        { path: 'taskmanagement', loadChildren: () => import('./task-management/task-management-routing.module').then(m => m.TaskManagementRoutingModule), canActivate: [AuthGuard] },
        { path: 'addoredittask', loadChildren: () => import('./add-or-edit-task/add-or-edit-task-routing.module').then(m => m.AddOrEditTaskRoutingModule), canActivate: [AuthGuard] },

        { path: 'flexisearch', loadChildren: () => import('./flexible-search-box/flexible-search-box-routing.module').then(m => m.FlexibleSearchBoxRoutingModule), canActivate: [AuthGuard] },


        { path: 'support-chat', loadChildren: () => import('./support-chat/support-chat-routing.module').then(m => m.SupportChatRoutingModule), canActivate: [AuthGuard] },

        { path: '', loadChildren: () => import('./login/login-module.module').then(m => m.LoginModuleModule) },
        { path: 'login', loadChildren: () => import('./login/login-module.module').then(m => m.LoginModuleModule), canActivate: [AuthGuard] },


        { path: 'cp', canActivate: [AuthGuard], loadChildren: () => import('./control-panel/control-panel-routing.module').then(m => m.ControlPanelRoutingModule) },
        { path: 'cpb', canActivate: [AuthGuard], loadChildren: () => import('./control-panel/control-panel-routing.module').then(m => m.ControlPanelRoutingModule) },
        { path: 'cpcc', canActivate: [AuthGuard], loadChildren: () => import('./control-panel/control-panel-routing.module').then(m => m.ControlPanelRoutingModule) },
        { path: 'cpdebug', canActivate: [AuthGuard], loadChildren: () => import('./control-panel/control-panel-routing.module').then(m => m.ControlPanelRoutingModule) },

        //{ path: 'artifactedit', loadChildren: () => import('./artifact-editor/artifact-editor.module').then(m => m.ArtifactEditorModule), canActivate: [AuthGuard] },
        { path: 'artifactedit', loadChildren: () => import('./artifact-editor/artifact-editor-routing.module').then(m => m.ArtifactEditorRoutingModule), canActivate: [AuthGuard] },

        { path: 'artifactlistformtest', loadChildren: () => import('./artifact-list/artifact-list-routing.module').then(m => m.ArtifactListRoutingModule), canActivate: [AuthGuard] },
        { path: 'scedulesurgey', loadChildren: () => import('./schedule-surgery/schedule-surgery-routing.module').then(m => m.ScheduleSurgeryRoutingModule), canActivate: [AuthGuard] },
        { path: 'emaildisplay', loadChildren: () => import('./email-display/email-display-routing.module').then(m => m.EmailDisplayRoutingModule), canActivate: [AuthGuard] },

        { path: 'stopwatch', loadChildren: () => import('./stop-watch/stop-watch-routing.module').then(m => m.StopWatchRoutingModule), canActivate: [AuthGuard] },
        { path: 'patientupload', loadChildren: () => import('./patient-upload/patient-upload-routing.module').then(m => m.PatientUploadRoutingModule), canActivate: [AuthGuard] },

        { path: 'differ', loadChildren: () => import('./differ/differ-routing.module').then(m => m.DifferRoutingModule), canActivate: [AuthGuard] },
        { path: 'notifications', loadChildren: () => import('./notification/notification-routing.module').then(m => m.NotificationRoutingModule), canActivate: [AuthGuard] },

        { path: 'noteschangetracker', loadChildren: () => import('./clinical-note-change-tracker/clinical-note-change-tracker-routing.module').then(m => m.ClinicalNoteChangeTrackerRoutingModule), canActivate: [AuthGuard] },

        { path: 'htmlraweditor/:artifactId', loadChildren: () => import('./html-editor/html-editor-routing.module').then(m => m.HtmlEditorRoutingModule), canActivate: [AuthGuard] },

        { path: 'range-rater', loadChildren: () => import('./range-rater/range-rater-routing.module').then(m => m.RangeRaterRoutingModule), canActivate: [AuthGuard] },
        { path: 'form-field-cloner', loadChildren: () => import('./form-field-cloner/form-field-cloner-routing.module').then(m => m.FormFieldClonerRoutingModule), canActivate: [AuthGuard] },
        { path: 'auto-email-field', loadChildren: () => import('./auto-email-field/auto-email-field-routing.module').then(m => m.AutoEmailFieldRoutingModule), canActivate: [AuthGuard] },

        { path: 'data-eval', loadChildren: () => import('./data-evaluator/data-evaluator-routing.module').then(m => m.DataEvaluatorRoutingModule), canActivate: [AuthGuard] },

        //, canActivate: [AuthGuard] 
        // { path: 'videocall', component: VideoCallComponent, canActivate: [AuthGuard] },

        { path: 'multiselector', loadChildren: () => import('./multi-selector/multi-selector-routing.module').then(m => m.MultiSelectorRoutingModule), canActivate: [AuthGuard] },

        { path: 'textinputcontrol', loadChildren: () => import('./text-input-control/text-input-control-routing-module.module').then(m => m.TextInputControlRoutingModuleModule), canActivate: [AuthGuard] },


        { path: 'imagefield', loadChildren: () => import('./image-field/image-field-routing.module').then(m => m.ImageFieldRoutingModule), canActivate: [AuthGuard] },
        { path: 'customcombobox', loadChildren: () => import('./custom-combo-box/custom-combo-box-routing.module').then(m => m.CustomComboBoxRoutingModule), canActivate: [AuthGuard] },


        // { path: 'scedulesurgey', loadChildren: () => import('./schedule-surgery/schedule-surgery.module').then(m => m.ScheduleSurgeryModule), canActivate: [AuthGuard] },


        // { path: 'memo', loadChildren: () => import('./memo/memo.module').then(m => m.MemoModule), canActivate: [AuthGuard] },
        { path: 'memo', loadChildren: () => import('./memo/memo-routing.module').then(m => m.MemoRoutingModule), canActivate: [AuthGuard] },


        //PROBLEM LAZY LOADING
        { path: 'diary', loadChildren: () => import('./my-diary/my-diary.module').then(m => m.MyDiaryModule), canActivate: [AuthGuard] },



        // { path: 'diary', loadChildren: () => import('./my-diary/my-diary-routing.module').then(m => m.MyDiaryRoutingModule), canActivate: [AuthGuard] },



        { path: 'diarytest', loadChildren: () => import('./diary/diary-routing.module').then(m => m.DiaryRoutingModule), canActivate: [AuthGuard] },


        { path: 'externalcomponenttest', loadChildren: () => import('./external/external-component-routing.module').then(m => m.ExternalComponentRoutingModule), canActivate: [AuthGuard] },
        { path: 'externalperson', loadChildren: () => import('./external-person/external-person-routing.module').then(m => m.ExternalPersonRoutingModule), canActivate: [AuthGuard] },
        { path: 'externalformtest', loadChildren: () => import('./external-form/external-form-routing.module').then(m => m.ExternalFormRoutingModule), canActivate: [AuthGuard] },
        { path: 'externalresultstest', loadChildren: () => import('./external-results/external-results-routing.module').then(m => m.ExternalResultsRoutingModule), canActivate: [AuthGuard] },
        { path: 'externalcovidtest', loadChildren: () => import('./external-covid/external-covid-routing.module').then(m => m.ExternalCovidRoutingModule), canActivate: [AuthGuard] },
        { path: 'externalpantest', loadChildren: () => import('./external-pan/external-pan-routing.module').then(m => m.ExternalPanRoutingModule), canActivate: [AuthGuard] },
        { path: 'external', loadChildren: () => import('./external/external-component-routing.module').then(m => m.ExternalComponentRoutingModule) },
        { path: 'external2', loadChildren: () => import('./external-user-vrify/external-user-verify-routing.module').then(m => m.ExternalUserVerifyRoutingModule) },
        { path: 'external/:redirect', loadChildren: () => import('./external/external-component-routing.module').then(m => m.ExternalComponentRoutingModule) },


        { path: 'patient', loadChildren: () => import('./patient/patient-routing.module').then(m => m.PatientRoutingModule), canActivate: [AuthGuard] },
        // { path: 'patient', loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule), canActivate: [AuthGuard] },


        { path: 'form-builderdirect/:id', loadChildren: () => import('./form-builder/form-builder-routing.module').then(m => m.FormBuilderRoutingModule), canActivate: [AuthGuard] },
        { path: 'form-library/:libmode', loadChildren: () => import('./form-builder/form-builder-routing.module').then(m => m.FormBuilderRoutingModule), canActivate: [AuthGuard] },
        { path: 'formlib', loadChildren: () => import('./form-library/form-library-routing.module').then(m => m.FormLibraryRoutingModule), canActivate: [AuthGuard] },

        { path: 'form-builder-highlight-new/:libmode/:id', loadChildren: () => import('./form-builder/form-builder-routing.module').then(m => m.FormBuilderRoutingModule), canActivate: [AuthGuard] },


        { path: 'form-builder', loadChildren: () => import('./form-builder/form-builder-routing.module').then(m => m.FormBuilderRoutingModule), canActivate: [AuthGuard] },

        { path: 'templates', loadChildren: () => import('./manage-templates/manage-templates-routing.module').then(m => m.ManageTemplatesRoutingModule), canActivate: [AuthGuard] },
        { path: 'content', loadChildren: () => import('./content-view/content-view-routing.module').then(m => m.ContentViewRoutingModule) },


        { path: 'di', loadChildren: () => import('./inpatients/inpatients-routing.module').then(m => m.InpatientsRoutingModule), canActivate: [AuthGuard] },


        // { path: 'org', loadChildren: () => import('./manage-organization/manage-organization.module').then(m => m.ManageOrganizationModule), canActivate: [AuthGuardAdminFunctions] },
        { path: 'org', loadChildren: () => import('./manage-organization/manage-organization.module').then(m => m.ManageOrganizationModule), canActivate: [AuthGuardAdminFunctions] },



        // { path: 'contracts', component: ManageContractsComponent,  loadChildren: () => import('./manage-contracts/manage-contracts.module').then(m => m.ManageContradctsModule), canActivate: [AuthGuard] },
        { path: 'contracts', loadChildren: () => import('./manage-contracts/manage-contracts-routing.module').then(m => m.ManageContractsRoutingModule), canActivate: [AuthGuard] },

        { path: 'rehab', loadChildren: () => import('./rehabilitation/rehabilitation-routing.module').then(m => m.RehabilitationRoutingModule), canActivate: [AuthGuard] },


        { path: 'timesaver', loadChildren: () => import('./time-saver-calculator/time-saver-calculator.module').then(m => m.TimeSaverCalculatorModule) },

        { path: 'timesaverint', loadChildren: () => import('./time-saver-calculator/time-saver-calculator.module').then(m => m.TimeSaverCalculatorModule), canActivate: [AuthGuard] },
        { path: 'multilinetest', loadChildren: () => import('./multi-line/multi-line-routing.module').then(m => MultiLineRoutingModule), canActivate: [AuthGuard] },

        // { path: 'timesaver', loadChildren: () => import('./time-saver-calculator/time-saver-calculator-routing.module').then(m => m.TimeSaverCalculatorRoutingModule)},


        // { path: 'org-email-settings', loadChildren: () => import('./org-smtp-settings/org-smtp-settings.module').then(m => m.OrgSmtpSettingsModule), canActivate: [AuthGuardAdminFunctions] },

        { path: 'org-email-settings', loadChildren: () => import('./org-smtp-settings/org-smtp-settings.module').then(m => m.OrgSmtpSettingsModule), canActivate: [AuthGuardAdminFunctions] },






        //{ path: 'admincp', loadChildren: () => import('./admin-control-panel/admin-control-panel.module').then(m => m.AdminControlPanelModule), canActivate: [AuthGuard] },
        // NOT WORKING
        { path: 'admincp', loadChildren: () => import('./admin-control-panel/admin-control-panel-routing.module').then(m => m.AdminControlPanelRoutingModule), canActivate: [AuthGuard] },
        { path: 'sites', loadChildren: () => import('./manage-sites/manage-sites.module').then(m => m.ManageSitesModule), canActivate: [AuthGuard] },
        { path: 'healthcheck', loadChildren: () => import('./health-check/health-check.module').then(m => m.HealthCheckModule), canActivate: [AuthGuard] },

        { path: 'askalchemed', loadChildren: () => import('./ask-dr-will/ask-dr-will.module').then(m => m.AskDrWillModule), canActivate: [AuthGuard] },
        //{ path: 'askalchemed', loadChildren: () => import('./ask-dr-will/ask-dr-will-routing.module').then(m => m.AskDrWillRoutingModule), canActivate: [AuthGuard] },

        { path: 'manage-contacts', loadChildren: () => import('./contact-manager/contact-manager.module').then(m => m.ContactManagerModule), canActivate: [AuthGuard] },
        // { path: 'manage-contacts',  loadChildren: () => import('./contact-manager/contact-manager-routing.module').then(m => m.ContactManagerRoutingModule), canActivate: [AuthGuard] },

        // { path: 'sites',  loadChildren: () => import('./manage-sites/manage-sites-routing.module').then(m => m.ManageSitesRoutingModule), canActivate: [AuthGuard] },

        // { path: 'consult-types', component: ManageConsultTypesComponent, loadChildren: () => import('./manage-consult-types/manage-consult-types.module').then(m => m.ManageConsultTypesModule), canActivate: [AuthGuard] },
        { path: 'consult-types', component: ManageConsultTypesComponent, loadChildren: () => import('./manage-consult-types/manage-consult-types.module').then(m => m.ManageConsultTypesModule), canActivate: [AuthGuard] },

        // { path: 'form-generator', loadChildren: () => import('./form-generator/form-generator-routing.module').then(m => m.FormGeneratorRoutingModule), canActivate: [AuthGuard] },


        // { path: 'form-preview', loadChildren: () => import('./form-preview/form-preview-routing.module').then(m => m.FormPreviewRoutingModule), canActivate: [AuthGuard] },
        {
            path: 'form-preview',
            loadChildren: () => import('./form-preview/form-preview.module').then(m => m.FormPreviewModule), canActivate: [AuthGuard]
        },
        {
            path: 'form-generator',
            loadChildren: () => import('./form-generator/form-generator.module').then(m => m.FormGeneratorModule), canActivate: [AuthGuard]
        },




        {
            path: 'patinfo',
            loadChildren: () => import('./patient-info/patient-info.module').then(m => m.PatientInfoModule), canActivate: [AuthGuard]
        },

        {
            path: 'fileactions',
            loadChildren: () => import('./file-actions/file-actions.module').then(m => m.FileActionsModule), canActivate: [AuthGuard]
        },
        {
            path: 'help',
            loadChildren: () => import('./help-file/help-file-routing.module').then(m => m.HelpFileRoutingModule), canActivate: [AuthGuard]
        },
        {
            path: 'helpfile',
            loadChildren: () => import('./help-file/help-file-routing.module').then(m => m.HelpFileRoutingModule), canActivate: [AuthGuard]
        },

        {
            path: 'hospital',
            loadChildren: () => import('./patient-board/patient-board.module').then(m => m.PatientBoardModule), canActivate: [AuthGuard]
        },

        { path: 'code', loadChildren: () => import('./code-editor/code-editor.module').then(m => m.CodeEditorModule), canActivate: [AuthGuard] },
        { path: 'appkeyendpoints', loadChildren: () => import('./app-keys/app-keys.module').then(m => m.AppKeysModule), canActivate: [AuthGuard] },



        { path: 'imagecontent', loadChildren: () => import('./content-view/content-view.module').then(m => m.ContentViewModule) },
        { path: 'pdfcontent', loadChildren: () => import('./content-view/content-view.module').then(m => m.ContentViewModule) },
        { path: 'dummyroute', loadChildren: () => import('./appdirectives/shared-directives/shared-directives.module').then(m => m.SharedDirectivesModule), canActivate: [AuthGuard] },



        // { path: 'ts', loadChildren: () => import('./theatre-slate/theatre-slate.module').then(m => m.TheatreSlateModule), canActivate: [AuthGuard] },




        { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },



        { path: 'sl/:id', loadChildren: () => import('./short-links/short-links.module').then(m => m.ShortLinksModule) },



        { path: 'my-reports', loadChildren: () => import('./my-reports/my-reports.module').then(m => m.MyReportsModule), canActivate: [AuthGuard] },
        { path: 'surgery', loadChildren: () => import('./surgery-view/surgery-view.module').then(m => m.SurgeryViewModule), canActivate: [AuthGuard] },
        { path: 'wysiwyg', loadChildren: () => import('./wysiwyg-editor/wysiwyg-editor.module').then(m => m.WysiwygEditorModule), canActivate: [AuthGuard] },
        { path: 'perfstats', loadChildren: () => import('./perf-stats/perf-stats.module').then(m => m.PerfStatsModule) },
        { path: 'orgstats', loadChildren: () => import('./org-stats/org-stats.module').then(m => m.OrgStatsModule) },
        { path: 'apireplay', loadChildren: () => import('./api-replay/api-replay-routing.module').then(m => m.AppKeysRoutingModule), canActivate: [AuthGuard] },
        { path: 'custsummary', loadChildren: () => import('./patient-summary/patient-summary-routing.module').then(m => m.PatientSummaryRoutingModule), canActivate: [AuthGuard] },
        { path: 'billr', loadChildren: () => import('./billing-request/billing-request.module').then(m => m.BillingRequestModule), canActivate: [AuthGuard] },
        { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule), canActivate: [AuthGuard] },
        { path: 'bisearch', loadChildren: () => import('./bi/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule), canActivate: [AuthGuard, AuthGuardAdvancedSearch] },
        { path: 'workflowmon', loadChildren: () => import('./workflow-monitor/workflow-monitor.module').then(m => m.WorkflowMonitorModule), canActivate: [AuthGuard] },



        //{ path: 'entitysearchtest', loadChildren: () => import('./entity-serach/entity-serach.module').then(m => m.EntitySerachModule), canActivate: [AuthGuard] },

        { path: 'entitysearchtest', loadChildren: () => import('./entity-serach/entity-serach-routing.module').then(m => m.EntitySerachRoutingModule), canActivate: [AuthGuard] },

        { path: 'whatsnew', loadChildren: () => import('./whats-new/whats-new-routing.module').then(m => m.WhatsNewRoutingModule), canActivate: [AuthGuard] },

        { path: 'htmlviewer', loadChildren: () => import('./html-file-viewer/html-file-viewer-routing.module').then(m => HtmlFileViewerRoutingModule), canActivate: [AuthGuard] },

        { path: 'payasyougo', loadChildren: () => import('./pay-as-you-go/pay-as-you-go-routing.module').then(m => m.PayAsYouGoRoutingModule), canActivate: [AuthGuard] },

        { path: 'artifactuploadest', loadChildren: () => import('./artifact-upload/artifact-upload.module').then(m => m.ArtifactUploadModule), canActivate: [AuthGuard] },
        { path: 'notesdashboardtest', loadChildren: () => import('./notes-dashboard/notes-dashboard.module').then(m => m.NotesDashboardModule), canActivate: [AuthGuard] },
        { path: 'userselectentitytest', loadChildren: () => import('./user-select-entity/user-select-entity.module').then(m => m.UserSelectEntityModule), canActivate: [AuthGuard] },
        { path: 'slideshowtest', loadChildren: () => import('./slide-show/slide-show.module').then(m => m.SlideShowModule), canActivate: [AuthGuard] },
        { path: 'formdev/:id', loadChildren: () => import('./artiform-developer/artiform-developer.module').then(m => m.ArtiformDeveloperModule), canActivate: [AuthGuard] },
        { path: 'custom-lists', loadChildren: () => import('./custom-lists/custom-lists.module').then(m => m.CustomListsModule), canActivate: [AuthGuard] },
        { path: 'manage-folders', loadChildren: () => import('./manage-folders/manage-folders.module').then(m => m.ManageFoldersModule), canActivate: [AuthGuard] },
        { path: 'app-keys', loadChildren: () => import('./application-keys/application-keys.module').then(m => m.ApplicationKeysModule), canActivate: [AuthGuard] },
        { path: 'shortcut-keys', loadChildren: () => import('./clinical-keys/clinical-keys.module').then(m => m.ClinicalKeysModule), canActivate: [AuthGuard] },
        { path: 'configure-diary', loadChildren: () => import('./configure-diary/configure-diary.module').then(m => m.ConfigureDiaryModule), canActivate: [AuthGuard] },
        { path: 'setup-assist', loadChildren: () => import('./setup-assist-item/setup-assist-item.module').then(m => m.SetupAssistItemModule), canActivate: [AuthGuard] },
        { path: 'xero', loadChildren: () => import('./xero/xero-export.module').then(m => m.XeroExportModule), canActivate: [AuthGuard] },
        { path: 'vital-signs', loadChildren: () => import('./vital-signs/vital-signs.module').then(m => m.VitalSignsModule), canActivate: [AuthGuard] },
        { path: 'demo-tools', loadChildren: () => import('./demo-tools/demo-tools.module').then(m => m.DemoToolsModule), canActivate: [AuthGuard] },
        { path: 'audioaitranscribe', loadChildren: () => import('./audio-aitranscriber/audio-aitranscriber.module').then(m => m.AudioAITranscriberModule), canActivate: [AuthGuard] },
        { path: 'learning', loadChildren: () => import('./did-you-know/did-you-know.module').then(m => m.DidYouKnowModule), canActivate: [AuthGuard] },
        { path: 'medaids', loadChildren: () => import('./medical-aid-schemes/medical-aid-schemes-routing.module').then(m => m.MedicalAidSchemesRoutingModule), canActivate: [AuthGuard] },
        { path: 'digsign', loadChildren: () => import('./digital-signing/digital-signing-routing.module').then(m => m.DigitalSigningRoutingModule), canActivate: [AuthGuard] },



        { path: 'patientrelink', component: PatientRelinkComponentComponent, loadChildren: () => import('./patient-relink-component/patient-relink.module').then(m => m.PatientRelinkModule), canActivate: [AuthGuard] },


        { path: 'custdatepicker', loadChildren: () => import('./custom-date-picker/custom-date-picker.module').then(m => m.CustomDatePickerModule), canActivate: [AuthGuard] },

        { path: 'theatreslateformcomponent', loadChildren: () => import('./theatre-slate-form/theatre-slate-form.module').then(m => m.TheatreSlateFormModule), canActivate: [AuthGuard] },

        { path: 'patientsearch', component: PatientSearchComponent, canActivate: [AuthGuard] },







        { path: 'icd', component: IcdManagerComponent, loadChildren: () => import('./icd-manager/icd-manager.module').then(m => m.IcdManagerModule), canActivate: [AuthGuard] },




        { path: 'pcodes', component: ManageProcedureCodesComponent, loadChildren: () => import('./manage-procedure-codes/manage-procedure-codes.module').then(m => m.ManageProcedureCodesModule), canActivate: [AuthGuard] },


        { path: 'itemselect', loadChildren: () => import('./shared/item-selector/item-selector.module').then(m => m.ItemSelectorModule), canActivate: [AuthGuard] },

        { path: 'mcodes', loadChildren: () => import('./manage-material-codes/manage-material-codes.module').then(m => m.ManageMaterialCodesModule), canActivate: [AuthGuard] },

        { path: 'userconfigure', loadChildren: () => import('./user-configuration/user-configuration-routing.module').then(m => m.UserConfigurationRoutingModule), canActivate: [AuthGuard] },


        // { path: 'mcodes', component: ManageMaterialCodesComponent, loadChildren: () => import('./manage-material-codes/manage-material-codes.module').then(m => m.ManageMaterialCodesModule), canActivate: [AuthGuard] },



        // { path: 'consult-types', component: ManageConsultTypesComponent, loadChildren: () => import('./manage-consult-types/manage-consult-types.module').then(m => m.ManageConsultTypesModule), canActivate: [AuthGuard] },


        // { path: 'sites', component: ManageSitesComponent, loadChildren: () => import('./manage-sites/manage-sites.module').then(m => m.ManageSitesModule), canActivate: [AuthGuard] },


        { path: 'audrec', loadChildren: () => import('./audio-recorder/audio-recorder.module').then(m => m.AudioRecorderModule), canActivate: [AuthGuard] },
        { path: 'helpdesk', loadChildren: () => import('./support-desk/support-desk-routing.module').then(m => m.SupportDeskRoutingModule), canActivate: [AuthGuard] },
        { path: 'helpdeskusers', loadChildren: () => import('./support-desk-user/support-desk-user-routing.module').then(m => m.SupportDeskUserRoutingModule), canActivate: [AuthGuard] },
        { path: 'helpdesklogon', loadChildren: () => import('./support-desk-logon/support-desk-logon-routing.module').then(m => m.SupportDeskLogonRoutingModule), canActivate: [AuthGuard] },

        { path: 'training', loadChildren: () => import('./training/training-routing.module').then(m => m.TrainingRoutingModule), canActivate: [AuthGuard] },

        { path: 'backups', loadChildren: () => import('./backup-config/backup-config-routing.module').then(m => m.BackupConfigRoutingModule), canActivate: [AuthGuard] },



        { path: 'ticket', loadChildren: () => import('./ticket-dialog/ticket-dialog-routing.module').then(m => m.TicketDialogRoutingModule), canActivate: [AuthGuard] },
        { path: 'input-text', loadChildren: () => import('./user-input-text/user-input-text-routing.module').then(m => m.UserInputTextRoutingModule), canActivate: [AuthGuard] },

        { path: 'db', component: DashboardComponent, loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
        { path: 'templateselector', component: TemplateSelectorComponent, loadChildren: () => import('./form-builder/template-selector/template-selector.module').then(m => m.TemplateSelectorModule), canActivate: [AuthGuard] },
        { path: 'genobjedit', component: GenericObjectEditorComponent, loadChildren: () => import('./generic-object-editor/generic-object-editor.module').then(m => m.GenericObjectEditorModule), canActivate: [AuthGuard] },

        // { path: 'twofa', loadChildren: () => import('./two-factor-authentication/two-factor-authentication.module').then(m => m.TwoFactorAuthenticationModule) , canActivate: [AuthGuard] },


        //{path: 'form-editor/:id', component: FormBuilderComponent, loadChildren: () => import('./form-builder/form-detail/form-detail.module').then(m => m.FormDetailModule), canActivate: [AuthGuard]},

        { path: 'entityroot/:id', component: EntityrootComponent, loadChildren: () => import('./entityroot/entity-root.module').then(m => m.EntityRootModule), canActivate: [AuthGuard] },

        { path: 'user-management', canActivate: [AuthGuardAdminFunctions], loadChildren: () => import('./user-management/user-management-routing.module').then(m => m.UserManagementRoutingModule) },

        // {
        //     path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuardAdminFunctions],
        //     loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
        //     children: [
        //         { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuardAdminFunctions] },
        //         { path: 'change-email', component: ChangeEmailComponent, canActivate: [AuthGuardAdminFunctions] },
        //         { path: 'confirm-delete-user', component: ConfirmDeleteUserComponent, canActivate: [AuthGuardAdminFunctions] }
        //     ]
        // },



        //{ path: 'patient', loadChildren: () => import('./billing/billing-routing.module').then(m => m.BillingRoutingModule ), canActivate: [AuthGuard]},

        { path: 'payment-successful', loadComponent: () => import("./netcash/paynow-successful/paynow-success.component").then(c => c.PaynowSuccessComponent) },
        { path: 'payment-declined', loadComponent: () => import("./netcash/paynow-declined/paynow-declined.component").then(c => c.PaynowDeclinedComponent) },
        // otherwise redirect to home
        { path: '**', redirectTo: '', canActivate: [AuthGuard] },
        //    loadChildren: () => import('./items/items.module').then(m => m.ItemsModule)
    ];

}
else {
    routes = [
        // { path: '', component: HomeComponent },
        // { path: 'content/:publicKey/:id', component: ContentViewComponent },
        // { path: 'imagecontent/:publicKey/:id', component: ContentViewComponent },
        // { path: 'pdfcontent/:publicKey/:id', component: ContentViewComponent },

        // { path: 'external', loadChildren: () => import('./external/external-component.module').then(m => m.ExternalComponentModule) },
        // { path: 'perfstats', loadChildren: () => import('./perf-stats/perf-stats.module').then(m => m.PerfStatsModule) },
        // { path: 'orgstats', loadChildren: () => import('./org-stats/org-stats.module').then(m => m.OrgStatsModule) },
        // { path: 'sl/:id', component: ShortLinksComponent },

        { path: '**', redirectTo: '', canActivate: [AuthGuard] }
    ];

}

if (checkIfElectron() === true) {
    routes = electronroutes;
}

//var useHashing : boolean =  (window.location.hostname == "www.alchemint.net");


@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {



}


// Helper function to determine if the app is running in Electron
function checkIfElectron(): boolean {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}
