// electron.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ElectronGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedRoutes = ['devrobot', 'login', 'backups']; // Specify allowed routes
    const isElectron = this.checkIfElectron();

    if (isElectron && allowedRoutes.includes(route.routeConfig.path)) {
      // Allow 'login' and 'backups' routes in Electron
      return true;
    } else if (isElectron) {
      // Redirect to 'backups' if trying to access any other route in Electron
      // this.router.navigate(['/backups']);
      this.router.navigate(['/devrobot']);
      return false;
    } else {
      // If it's not Electron, normal navigation (you can further refine this if needed)
      return true;
    }
  }

  private checkIfElectron(): boolean {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
    }
  
    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
    }
  
    // Detect the user agent when the `nodeIntegration` is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
    }
  
    return false;
  }
}
