import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';

@Component({
  selector: 'app-qr-code-display',
  templateUrl: './qr-code-display.component.html',
  styleUrls: ['./qr-code-display.component.scss']
})
export class QrCodeDisplayComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public userSeletInfo: UserSeletInfo, public dialogRef: MatDialogRef<QrCodeDisplayComponent>) { }

  ngOnInit(): void {
  }

  public close() : void{
    this.dialogRef.close();
  }
}
