<!-- margin: 3px;  -->
<!-- min-width: 150px; -->
<!-- margin-left: 3px !important; -->
<div style="max-height: 300px;" >
    <div (click)="showPopup = !showPopup" id="menuTrigger" #xx id="xx"  [matMenuTriggerFor]="menu">
        <ng-content select="[popup-trigger]" ></ng-content>
    </div>
    <div class="menu-popup" #popup id="popup">
        <mat-menu #menu="matMenu">
            <ng-content select="[popup-options]" class="pop-options" (onMenuItemClick)="showPopup=!showPopup"></ng-content>
        </mat-menu>
    </div>
</div>
<ng-content></ng-content>
