import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICD10Code } from '@app/_alchemint/alchemint_dm';
import { Observable, shareReplay } from 'rxjs';
import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { EnvService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class IcdCodesService {

  private apiUri: string;

  constructor(private http: HttpClient, private envService: EnvService, private apiInterfaceService: ApiInterfaceService) {
    this.apiUri = this.envService.deploymentSettings.apiUrl;
  }

  public fetchAllIcdCodes$(): Observable<ICD10Code[]> {
    return this.http.get<ICD10Code[]>(`${this.apiUri}Billing/icd10codes`).pipe(shareReplay());
  }
}
