import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { EnvService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationServiceService {

  private companyLogo: ArrayBuffer;
  private companyLogoLoadFailed: boolean = false;
  apiUri: string;
  apiWebUiUrl: string;
  apiExtendedUrl: string;

  constructor(private apiInterfaceService: ApiInterfaceService, private http: HttpClient, private envService: EnvService) {
    this.apiUri = this.envService.deploymentSettings.apiUrl;
    this.apiWebUiUrl = this.envService.deploymentSettings.apiWebUiUrl;
    this.apiExtendedUrl = this.envService.deploymentSettings.apiExtendedUrl;
  }

  imageArrayToBase64(arrayBuffer: ArrayBuffer) {

    if (ImageTypeDetector.getImageType(new Uint8Array(arrayBuffer)) === ImageType.Svg) 
    {
      var svgString = new TextDecoder().decode(arrayBuffer);
      return 'data:image/svg+xml;base64,' + window.btoa(svgString);
    }
    else{
      var base64 = this.arrayBufferToBase64(arrayBuffer);
      var imageString: string = 'data:image/png;base64,' + base64;
      return imageString;
  
    }


  }

  public getOrganisationLogoAsBase64Image(apiInterfaceService: ApiInterfaceService): Observable<string> {
    return new Observable(
      observer => {
        this.loadAndAapplyOrganisationLogo(apiInterfaceService).subscribe
          (
            data => {
              
              observer.next(this.imageArrayToBase64(data));
            },
            err => {
              observer.error(err);
            }
          );
      }
    );
  }

  private loadAndAapplyOrganisationLogo(apiInterfaceService: ApiInterfaceService): Observable<ArrayBuffer> {
    return new Observable(
      observer => {

        if (this.companyLogoLoadFailed) {
          observer.next(null);
        }
        else if (this.companyLogo) {
          observer.next(this.companyLogo);
          //this.applyVariables (this.organization);
        }
        else {
          apiInterfaceService.getOrganizationLogoWebGuiApiCall().subscribe(
            coLogo => {
              this.companyLogo = coLogo;
              if (this.companyLogo) {
                observer.next(this.companyLogo);
                //this.organization = this.preloadData.organisations[0];
                //this.applyVariables (this.organization);
              }
            },
            err => {
              this.companyLogoLoadFailed = true;
              console.error(err);
              observer.error(err);

            }
          );
        }
      }
    );

  }

  private arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);

  }

  


}


enum ImageType {
  Unknown,
  Png,
  Jpg,
  Svg
}

class ImageTypeDetector {
  public static getImageType(imageData: Uint8Array): ImageType {
      if (!imageData || imageData.length < 4) {
          return ImageType.Unknown;
      }

      // Check for PNG (first 8 bytes should be: 137 80 78 71 13 10 26 10)
      if (this.isPng(imageData)) {
          return ImageType.Png;
      }

      // Check for JPG (first 3 bytes should be: 255 216 255)
      if (this.isJpg(imageData)) {
          return ImageType.Jpg;
      }

      // Check for SVG (look for "<svg" within the first 100 bytes)
      if (this.isSvg(imageData)) {
          return ImageType.Svg;
      }

      return ImageType.Unknown;
  }

  private static isPng(imageData: Uint8Array): boolean {
      const pngSignature = new Uint8Array([137, 80, 78, 71, 13, 10, 26, 10]);
      return this.arrayStartsWith(imageData, pngSignature);
  }

  private static isJpg(imageData: Uint8Array): boolean {
      const jpgSignature = new Uint8Array([255, 216, 255]);
      return this.arrayStartsWith(imageData, jpgSignature);
  }

  private static isSvg(imageData: Uint8Array): boolean {
      const initialData = new TextDecoder().decode(imageData.slice(0, 100));
      return initialData.includes('<svg');
  }

  private static arrayStartsWith(data: Uint8Array, signature: Uint8Array): boolean {
      if (data.length < signature.length) {
          return false;
      }
      for (let i = 0; i < signature.length; i++) {
          if (data[i] !== signature[i]) {
              return false;
          }
      }
      return true;
  }
}