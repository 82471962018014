// src/app/services/click-highlight.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable(
  
)
export class ClickHighlightService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  highlightElement(element: HTMLElement) {
    this.renderer.setStyle(element, 'transition', 'background-color 0.5s');
    this.renderer.setStyle(element, 'background-color', '#ff0'); // Temporary highlight color
    setTimeout(() => {
      this.renderer.removeStyle(element, 'background-color');
    }, 500); // Duration of highlight
  }
}
