import { Injectable } from "@angular/core";
import { Contact, ContactType } from "@app/_alchemint/alchemint_dm";
import { ApiInterfaceService } from "@app/_services/alchemint.apiinterface.service";
import { WebApiInterfaceService } from "@app/_services/alchemint.webapiinterface.service";
import { EnvService } from "@app/_services/environment.service";
import { map, Observable, shareReplay } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ContactManagerService {
    apiUri: string;
    apiWebUiUrl: string;
    apiExtendedUrl: string;

    constructor(private apiInterfaceService: ApiInterfaceService,  private envService: EnvService, private webApi: WebApiInterfaceService) {
        this.apiUri = this.envService.deploymentSettings.apiUrl;
        this.apiWebUiUrl = this.envService.deploymentSettings.apiWebUiUrl;
        this.apiExtendedUrl = this.envService.deploymentSettings.apiExtendedUrl;
    }


    public contacts(): Observable<Contact[]> {
        return this.webApi.getEntities<Contact>(new Contact())
            .pipe(
                map(contacts => contacts.sort((a, b) => {
                    const fullNameA = [a.title, a.firstName, a.lastName].filter(Boolean).join(' ');
                    const fullNameB = [b.title, b.firstName, b.lastName].filter(Boolean).join(' ');
                    return fullNameA.localeCompare(fullNameB);
                })),
                shareReplay()
            );
    }
    



    public fetchContactTypes(): Observable<ContactType[]> {
        return this.apiInterfaceService.getPreLoadDataAndSetGlobals().pipe(map(data => data.contactTypes));
    }
}