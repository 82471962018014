<style>
    /* div {
        border:solid;
    } */



</style>


    
    <label *ngIf="flagAsNewHyperlink" [style]="flagAsNewStyling" [hidden]="flagAsNewFeature===false" ><a [href]="flagAsNewHyperlink" class="" style="margin: 0px; padding:0px;" [style]="flagAsNewColorStyle" target="_blank">{{flagAsNewText}}</a></label>
    <label *ngIf="!flagAsNewHyperlink" [style]="flagAsNewStyling" [hidden]="flagAsNewFeature===false" >{{flagAsNewText}}</label>
   
    <div class="inlinehelp-tooltip-div" [hidden]="(ToolTipAvailable===false) || (toolTipIconVisible===false)" >
    
        <!-- [style]="iconColorStyle" -->
        <svg-icon src="assets/svgs/{{iconCode}}_white_24dp.svg" [hidden]="showAsInlineContent===true" class="custom-svg-icon-orange" (mouseenter)="showToolTip()" (touchstart)="showToolTip()" (mouseleave)="hideToolTip()" (touchend)="hideToolTip()"  [class]="svgColorClass" ></svg-icon>

        <div [hidden]="showAsInlineContent===false"> {{HelpContent}}</div>
    </div>
    
 
    <!-- </div> -->



    <a [hidden]="OnlineHelpAvailable===false" [href]="OnlineHelpLink" target="_blank" title="Goto Help">
        <svg-icon src="assets/svgs/help_white_24dp.svg" class="custom-svg-icon-orange" ></svg-icon>
    </a>
    
    <a *ngIf="inLineAssistanceItem?.videoLink">
        


        <!-- [style]="iconColorStyle" -->
        <svg-icon src="assets/svgs/videocam_white_24dp.svg" [hidden]="(this.videoVisible===true) || (hideOpenCloseIcon===true)" (click)="showVideo()" Title="View Help Video" class="custom-svg-icon-orange" [class]="svgColorClass" ></svg-icon>

        <svg-icon src="assets/svgs/close_white_24dp.svg" [hidden]="(this.videoVisible===false) || (hideOpenCloseIcon===true)" (click)="showVideo()" Title="Close" class="custom-svg-icon-orange"  [style]="iconColorStyle"></svg-icon>

        <label *ngIf="showVideoName" (click)="showVideo()">
            {{showVideoName}}
        </label>

        
        <div *ngIf="(videoVisible==true) && (inLineAssistanceItem?.useIFrmaeForVideos===true)" style="background-color:white; margin:3em; padding:2em;"> -
            
            <iframe  title="vimeo-player" [src]="sanitizedVideo" width="640" height="360" frameborder="0" allowfullscreen></iframe>
            <!-- <iframe  title="vimeo-player" src="https://vimeo.com/user159324637" width="640" height="360" frameborder="0" allowfullscreen></iframe> -->
        </div>    


        <div *ngIf="(videoVisible===true) && (inLineAssistanceItem?.useIFrmaeForVideos===false)" style="background-color:white; margin:3em; padding:2em;">
            
            <video controls [src]="inLineAssistanceItem?.videoLink" type="video/mp4" [autoplay]="isSafari===false">
            </video>
        </div>    
        
        

    </a>
    
    <a *ngIf="documentationMode===true" id="debug_label" [hidden]="(OnlineHelpAvailable===true) || (ToolTipAvailable===true)" (click)="creatDocumentationObjectOnClipBoard()">
        {{uiItemIdentifier}}    
    </a>

    <div *ngIf="(inLineAssistanceService.AllowContentEditing==true && (documentationMode===true))" >
        <textarea [style]="editStyle" [(ngModel)]="HelpContent" >
        </textarea>
    </div>

<!-- </div> -->




    <!-- <span> Click the following buttons to... </span> -->
    <!-- <button mat-button
            (click)="tooltip.show()"
            aria-label="Show tooltip on the button at the end of this section"
            class="example-action-button">
      show
    </button>
    <button mat-button
            (click)="tooltip.hide()"
            aria-label="Hide tooltip on the button at the end of this section"
            class="example-action-button">
      hide
    </button>
    <button mat-button
            (click)="tooltip.toggle()"
            aria-label="Show/Hide tooltip on the button at the end of this section"
            class="example-action-button">
      toggle show/hide
    </button>
  </div> -->
  <div style="float: right;" [hidden]="(ToolTipAvailable===false) || (toolTipIconVisible===false)"> 

    <span mat-raised-button #tooltip="matTooltip" style="font-size: 30px;"
    [matTooltip]="HelpContent"
    matTooltipPosition="right"
    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
    style="white-space: pre-line"
    >

    </span>
  </div>
  
