import { ElementRef, EventEmitter, Injectable, Output } from '@angular/core';
import { WordDocxViewerComponent } from '@app/word-docx-viewer/word-docx-viewer.component';
import { Artifact, ArtifactType } from '@app/_alchemint/alchemint_dm';
import { Observable } from 'rxjs';
import { catchError, map, max, switchMap } from 'rxjs/operators';

import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { HttpClient } from '@angular/common/http';
import { ArtifactWithData, ArtifactWithFile } from '@app/_alchemint/alchemint_composite_requests';

@Injectable({
  providedIn: 'root'
})
export class ImageManipulationService {
  entity: Artifact;
  
  @Output() uploadedFile = new EventEmitter<ArtifactWithFile>();
  @Output() errorEvent = new EventEmitter<string>();

  @Output() startedWorking = new EventEmitter();
  @Output() finishedWorking = new EventEmitter();

  constructor(public apiInterfaceService : ApiInterfaceService, private alchemintSharedService: AlchemintSharedService, private http: HttpClient) { 
    

  }
  fileSizeBeforeResize: number;

  resizeIterations: number;
  
  resizeImage(file:File, maxWidth:number, maxHeight:number, factor : number):Observable<Blob> {
  
    return new Observable<Blob>(observer => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            let width = image.width;
            let height = image.height;
            

            if (factor != 0)
            {
              maxWidth = width * factor;
              maxHeight = height * factor;
  
            }
            else
            {

            }

            if (width <= maxWidth && height <= maxHeight) {
              observer.next(file);
              //resolve(file);
            }

            let newWidth;
            let newHeight;

            if (factor != 0)
            {
              newHeight = maxHeight;
              newWidth = maxWidth;

            }
            else if (width > height) {
                newHeight = height * (maxWidth / width);
                newWidth = maxWidth;
            } else {
                newWidth = width * (maxHeight / height);
                newHeight = maxHeight;
            }

            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            let context = canvas.getContext('2d');

            context.drawImage(image, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              bl => {
                observer.next(bl);
              }, file.type
            )



            // canvas.toBlob(function(blob) {
            //   var newImg = document.createElement('img'),
            //       url = URL.createObjectURL(blob);
            
            //   newImg.onload = function() {
            //     // no longer need to read the blob so it's revoked
            //     URL.revokeObjectURL(url);
            //   };
            
            //   newImg.src = url;
            //   document.body.appendChild(newImg);
            //});

            //observer.next(file);
            //canvas.toBlob(resolve, file.type);
        };
        image.onerror = this.reject;
    });
}


public blobToFile = (theBlob: Blob, fileName:string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  
  //Cast to a File() type
  return <File>b;

}
public blobToFile2 = (theBlob: Blob, fileName:string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  var file : File = new File([theBlob], fileName, {type: this.getMimeType(fileName.split('.').pop())});
  
  //file.lastModified = new Date();
  //Cast to a File() type
  return file;

}

getMimeType(extension: string): string {
  const mimeTypes: { [key: string]: string } = {
      'html': 'text/html',
      'htm': 'text/html',
      'css': 'text/css',
      'scss': 'text/x-scss',
      'js': 'application/javascript',
      'json': 'application/json',
      'xml': 'application/xml',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'svg': 'image/svg+xml',
      'txt': 'text/plain',
      'pdf': 'application/pdf',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  };

  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
}


public reject (errorMsg, url, lineNumber, column, errorObj) : void{
  alert ('Could not resize image');
}

public resizeFileIfRequired (file : File, imageFileResizePercent : number) : Observable<File>
{
  return new Observable<File> (
    observer => 
    {
      if (imageFileResizePercent != 100 ) // file.size > this.apiInterfaceService.maxFileSize)
      {
        // Do Resize
        this.resizeImage(file, 0,0, (imageFileResizePercent / 100)).subscribe(blob => {
          //You can upload the resized image: doUpload(blob)
          
          var fl2 : File = this.blobToFile2(blob, file.name);

          observer.next(fl2);
          //document.getElementById('img').src = URL.createObjectURL(blob);
        }, err => {
          console.error("Photo error", err);
          observer.next(null);
        });

      }
      else 
      {
        observer.next(file);
        //alert(`The file is too large. A maximum size of ${this.apiInterfaceService.maxFileSize} MegaBytes is allowed.`);
        return;

      }

    }
  )
}

public isFileAboveMaxLimitSetting(file : File, apiInterfaceService : ApiInterfaceService) : boolean
{
  if (file.size > apiInterfaceService.maxFileSize)
  {
    return true;
  }
  else
  {
    return false;
  }
}


resizeFileUntilSmallerThan (file : File, sizeMax : number, percentToReduceTo : number) : Observable<File>
{
  
  var sizeBeforeResize : number = file.size;
  // console.log(`Size : ${sizeBeforeResize}. Resize Iteration : ${this.resizeIterations}`);

  if (sizeBeforeResize <= sizeMax)
  {
    return new Observable<File> ( observer => {
      observer.next(file);
      observer.complete();
    });
  }


  return new Observable<File> (observer =>
    {
      if (sizeBeforeResize < sizeMax)
      {
        observer.next(file)
      }
      else
      {
        this.resizeFileIfRequired(file, percentToReduceTo).subscribe(
          fileResult =>
          {
            this.resizeIterations ++;
            var sizeAfterResize : number = fileResult.size ;
            //Anoter resize required
            if (sizeAfterResize > sizeMax)
            {
              this.resizeFileUntilSmallerThan (fileResult , sizeMax, percentToReduceTo).subscribe(
                fileRes2 => {
                  observer.next(fileRes2);
                  observer.complete();
                },
                error2 => { 
                  this.errorEvent?.emit(error2);
                }
              );

            }
            else
            {
              observer.next(fileResult);
              observer.complete();
            }
            
          }, 
          err => {
            this.errorEvent?.emit(err);
          }
        );
      }
    }
  );

}

public creatArtifactFromFile (file : File, apiInterfaceService : ApiInterfaceService, patientId : string, artifactTypeId : string)
{
  if (file.size > apiInterfaceService.maxFileSize)
  {
    this.alchemintSharedService.messageDialogSync(`The file is too large. A maximum size of ${(apiInterfaceService.maxFileSize / 1000000)} MegaBytes is allowed and the file you are attempting to upload is ${file.size/1000000} MegaBytes.`);
    return;
  }
  var fileName = file.name;
  //const formData = new FormData();
  //formData.append("thumbnail", file, fileName);
  this.entity = new Artifact();
  this.entity.name = fileName;

  //var parentId : string = this.patientId;
  var parentId : string = patientId;

  const upload$ = apiInterfaceService.createArtifact(parentId ,file, artifactTypeId, null, null);
  upload$.subscribe(updatedArtifact => {
      this.entity = updatedArtifact; 
      var artifactWithFile : ArtifactWithFile = new ArtifactWithFile(updatedArtifact, file);
      this.uploadedFile.emit(artifactWithFile);
  }, 
    error => 
    {
      if (error.status == 412)
      {
        alert("The document has been edited by someone else. Please reload the document and re-apply your changes and then save it.");
      }
      else
      {
        alert(error.statusText);
      }
      
    }
  );
  return;
}

uploadFileWithResizeAttempts (file : File, patientId : string, artifactTypeId : string)
{
  if (file && (this.isImageFile(file))) 
  {
    this.fileSizeBeforeResize = file.size;
    this.resizeIterations = 0;
    var percentToReduceTo : number = 95; //this.imageFileResizePercent  
    var maxSize : number = this.apiInterfaceService.maxFileSize;

    this.startedWorking?.emit();
    this.resizeFileUntilSmallerThan(file, maxSize, percentToReduceTo).subscribe(
      resizedFile => {
        this.finishedWorking?.emit();
        this.creatArtifactFromFile(resizedFile,this.apiInterfaceService, patientId, artifactTypeId);
        console.log (`Original file size : ${this.fileSizeBeforeResize}. New Size ${resizedFile.size}. Percent saving ${100 * (this.fileSizeBeforeResize - resizedFile.size) / this.fileSizeBeforeResize}. This took ${this.resizeIterations} resize iterations to achieve using ${percentToReduceTo} percent increments.`);
      },
      error => {
        this.finishedWorking?.emit();
      }
    );
  }
  else
  {
    this.creatArtifactFromFile(file,this.apiInterfaceService, patientId, artifactTypeId);
  }
}

isImageFile (file : File) : boolean
{
  if (
     (file?.type?.endsWith('/png')) || 
     (file?.type?.endsWith('/jpg')) || 
     (file?.type?.endsWith('/gif')) || 
     (file?.type?.endsWith('/jpeg'))
     )
  {
    return true;
  }
  else
  {
    return false;
  }
}


  ImageDataToBlob = function(imageData) {
    let w = imageData.width;
    let h = imageData.height;
    var canvas = document.createElement("canvas");
    
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx.putImageData(imageData, 0, 0);        // synchronous

    return new Promise((resolve) => {
          canvas.toBlob(resolve); // implied image/png format
          (<HTMLElement>canvas).remove;
    });
  }


  printElement(elementId : string) : boolean
  {
    const elem = document.getElementById(elementId);
    if (elem)
    {
      return this._printElement(elem.innerHTML);
    }
    else
    {
      throw `Element of Id ${elementId} not found`;
    }
  }

  printNativeElement(nativePrintableElement : HTMLElement) : boolean
  {
    if (nativePrintableElement)
    {
      return this._printElement(nativePrintableElement.innerHTML);
    }
    else
    {
      throw `Element of is null`;
    }
  }

  

  printHtml(htm : string) : boolean
  {
    return this._printElement(htm);
  }
  
  _printElement(innerHTML : string) : boolean
  {
    var mywindow : Window = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head>');

    mywindow.document.write('<title></title>');
    

    //mywindow.document.write('<style> img { max-height: 1500px; } </style>');
    mywindow.document.write('<style> img { } </style>');
    mywindow.document.write('<style> @page { size: A4; margin: 4cm; }    @media print { html, body { width: 210mm; height: 297mm;} </style>');
    
    

    // mywindow.document.write('</head><body style="width: 210mm; height: 297mm;">');
    mywindow.document.write('</head><body>');
    mywindow.document.write(innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;

  }
  
  
  imageArrayToBase64 (arrayBuffer : ArrayBuffer)
  {
    var base64 = this.arrayBufferToBase64 (arrayBuffer);
    var imageString : string = 'data:image/png;base64,' + base64;
    return imageString;
  }

  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );

  }


  // Function to convert image URL to a base64 string
  public convertImageToBase64(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      switchMap(blob => this.blobToBase64(blob)), // Use switchMap to properly flatten the Observable
      catchError(error => {
        console.error('Error in fetching or converting the image:', error);
        return new Observable<string>(observer => {
          observer.error('Error in converting image');
        }); // Return an Observable that immediately errors
      })
    );
  }

  private blobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
      reader.readAsDataURL(blob);
    });
  }

}
