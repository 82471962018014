import { ElementRef, Renderer2 } from "@angular/core";
import { IReturnValue } from "./_alchemint/alchemint_composite_requests";
import { FormFieldDescriberExtended } from "./external-form/external-form.component";

export function shouldDisplayDueToBranchLogic(
    ffd: FormFieldDescriberExtended, 
    defferredLoadComponents: [string, any, IReturnValue][],
    el: ElementRef<any>, 
    renderer: Renderer2
    ): boolean {
    

    if (ffd && defferredLoadComponents)
    {
      const condition = ffd.field.showCondition;    //if (!condition) return true; // No condition means always display


      if (condition.conditionsfield === ffd.field.label)
      {
        return true;
      }
  
      //var formControl = theForm?.controls[condition.conditionsfield];
      
      const targetField = defferredLoadComponents.find(f => f[0] == `[Form.${condition.conditionsfield}]`);   //.fields.find(f => f.field.label === condition.conditionsfield);
      //const targetField = this.fields.find(f => f.field.label === condition.conditionsfield);
      if (!targetField) return true; // Referenced field not found
  
      var ireturnValue : IReturnValue = targetField[2];
      var ret: boolean;

      const value = ireturnValue.value;
      const valueType = typeof value;
      
      var checkValue: any;
      if (valueType === 'boolean')
      {
        checkValue = value.toString();
      }
      else
      {
        checkValue = value;
      }
  



      switch (condition.comparison) {
        case '>':
          ret = (checkValue > condition.value);
          break;
        case '<':
          ret = checkValue < condition.value;
          break;
        case '=':
          ret = checkValue == condition.value;
          break;
        case '<=':
          ret = checkValue <= condition.value;
          break;
        case '>=':
          ret = checkValue >= condition.value;
          break;
        case '!=':
          ret = checkValue != condition.value;
          break;
        case 'hasvalue':
          ret = (checkValue > '');
          break;
        default:
          ret = false; // Or any default logic you want
       }
  
       if (ret === true)
       {
        try {
          (<any> targetField[2]).glow = true;
        } catch (error) {
          console.error(error);
        }
       }
  
  
    }
    else
    {
      ret =  true;
    }


    if (!ret) 
    {
      hideElement(el, renderer);
    } else {
      
      showElement(el, renderer);
    }

    return ret;

  }


  function hideElement(el: ElementRef, renderer: Renderer2)
  {
         // Hide the component
         renderer.setStyle(el.nativeElement, 'display', 'none');
         // Hide the parent element
         const parentElement = renderer.parentNode(el.nativeElement);
         if (parentElement) {
           renderer.setStyle(parentElement, 'display', 'none');
           const grandParentElement = renderer.parentNode(parentElement);
           renderer.setStyle(grandParentElement, 'display', 'none');
         }
  }

  function showElement(el: ElementRef, renderer: Renderer2)
  {
          // Show the component and the parent
          renderer.removeStyle(el.nativeElement, 'display');
          const parentElement = renderer.parentNode(el.nativeElement);
          if (parentElement) {
            renderer.removeStyle(parentElement, 'display');
            const grandParentElement = renderer.parentNode(parentElement);
            renderer.removeStyle(grandParentElement, 'display');
          }
  }


export interface ICanBranchLogic
{
  renderer: Renderer2; 
  el: ElementRef
  formFieldDescriberExtended: FormFieldDescriberExtended;
  defferredLoadComponents: [string, any, IReturnValue][];

  shouldDisplay(): boolean;
}
