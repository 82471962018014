

<div [style]="style" [hidden]="shouldDisplay()===false">
    <!-- <label for="textarea" [title]="labelTitle" [style]="getStyleFor(form_elem)">{{form_elem.verboseFieldLabel}} &nbsp;</label>  -->

    <!-- [readonly]="(readOnly || ReadOnlyMode)" class="{{form_elem.field.invalidationStyle}}"     -->
    <textarea #textAreaEditor  [(ngModel)]="textData" rows ="5" class="form-control" [readonly]="readOnly" [class]="classing" [style]="textAreaStyle">
    </textarea>
    <div *ngIf="(isExternalRqMode===false)" class="transcriber-container" style="padding:0.1em;">
        <app-transcribe-tools [textAreaControl]="textAreaEditorref" 
    
        [styling]="'height:1cm; width:auto;'"
        [specificMode]=10
        >
        </app-transcribe-tools>
        <div *ngIf="glow===true" class="glowing-dot"></div>
    </div>

    <!-- <label>
        GLOW : {{glow}}

    </label> -->
    

</div>



