import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, share, shareReplay, tap, throwError } from 'rxjs';
import { EnvService } from '@app/_services/environment.service';
import { MatDialog } from '@angular/material/dialog';
import { MultiFactorAuthComponent } from './mult-factor-auth.component';
import { AuthenticationService } from '@app/_services';
import { AlertService as ToastService } from 'fd-alerts';
import { boolean } from 'mathjs';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorAuthenticationService {


  private apiUrl: string;
  private apiWebUiUrl: string;

  constructor(private http: HttpClient, private userService: AuthenticationService, private dialog: MatDialog, private envService: EnvService, private toastService: ToastService) {
    this.apiUrl = this.envService.deploymentSettings.apiUrl;
    this.apiWebUiUrl = this.envService.deploymentSettings.apiWebUiUrl;
  }

  enableTwoFactorAuth(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/enable-mfa`, {}).pipe(
      catchError(this.handleError)
    );
  }

  enable2FAWithWhatsApp(whatsAppNumber: string) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/enable`, {
      userLoginId: this.userService.userIdFromToken,
      channel: 'WHATSAPP',
      phoneNumber: whatsAppNumber,
    }).pipe(catchError((error) => this.handleError(error)));
  }

  enable2FAWithSMS(whatsAppNumber: string) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/enable`, {
      userLoginId: this.userService.userIdFromToken,
      channel: 'SMS',
      phoneNumber: whatsAppNumber,
    }).pipe(catchError((error) => this.handleError(error)));
  }

  enable2FAWithEmail(emailAddress: string) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/enable`, {
      userLoginId: this.userService.userIdFromToken,
      channel: 'EMAIL',
      emailAddress,
    }).pipe(catchError((error) => this.handleError(error)));
  }

  enable2FAWithApp() {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/enable`, {
      userLoginId: this.userService.userIdFromToken,
      channel: 'APP'
    }).pipe(catchError((error) => this.handleError(error)));
  }

  public sendOTP(userLoginId: string) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/sendotp`, { userLoginId: userLoginId })
      .pipe(share(), catchError((error) => this.handleError(error)));
  }

  public confirmOTP(userLoginId: string, otp: string) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/confirmotp`, { userLoginId: userLoginId, OTP: otp })
      .pipe(catchError((error) => this.handleError(error)));
  }

  public completeMfaSetup(payload: { code: number, recoveryPhoneNumber: string, recoveryEmail: string }) {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/complete-setup`, {
      userLoginId: this.userService.userIdFromToken,
      ...payload
    }).pipe(catchError((error) => this.handleError(error)));
  }

  public VerifyUserOtp(code: number): Observable<{ "success": boolean, "recoveryEmail": string, "recoveryPhone": string }> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/validate-otp`, {
      userLoginId: this.userService.userIdFromToken,
      OTP: code,
    }).pipe(catchError((error) => this.handleError(error)));
  }

  disableTwoFactorAuth(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/disable`, { userLoginId: this.userService.userIdFromToken }).pipe(
      catchError(this.handleError)
    ).pipe(tap(() => {
      this.userService.updateUser2FaChannel("APP", this.userService.userIdFromToken, false);
    }));
  }

  verifyTwoFactorAuth(code: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/verify`, { code }).pipe(catchError((error) => this.handleError(error)));
  }

  requestRecoveryOtp(recoveryChannel: string, userLoginId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/recovery`, { channel: recoveryChannel, userLoginId }).pipe(catchError((error) => this.handleError(error)));
  }

  confirmRecoveryOtp(otp: string, userLoginId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}twoFactorAuthentication/confirm-recovery-otp`, { otp, userLoginId }).pipe(catchError((error) => this.handleError(error)));
  }

  public suggestMFA() {
    this.dialog.open(MultiFactorAuthComponent, {
      disableClose: true
    });
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Something went wrong. Please try again later.';
    if (error.error && typeof error.error === 'string') {
      errorMessage = (error.error.length > 100) ? error.error.substring(0, 100) : error.error;
    } else if (error.error instanceof ErrorEvent) {
      errorMessage = 'An error occurred: ' + (error.error.message.length > 100) ? error.error.message.substring(0, 100) : error.error.message;
    } else if (error.status === 400) {
      errorMessage = error.error.message;
    } else if (error.status === 401) {
      errorMessage = 'Unauthorized access. Please log in.';
    } else if (error.status === 403) {
      errorMessage = 'You do not have permission to access this resource.';
    } else if (error.status === 404) {
      errorMessage = 'Resource not found.';
    }

    this.toastService.showError(errorMessage);
    return throwError(errorMessage);
  }
}
