<div *ngIf="(error) && (isOnline==false)" style="color:red; background-color: transparent; width: 400px;">
    <br>
    You appear to be offline. Please verify that you have a valid internet connection and that you can reach {{apiServer}}.
    If you have a valid internet connection but you cannot reach {{apiServer}} then it is possible that your IT personel 
    have blocked access to this domain. If this is the case please rectify this and try again.
    <br>
    <br>
</div>

<div id="errorBox" [hidden]="hideMainErrorBox">
    <div class="col-md-6 offset-md-3 mt-5" *ngIf="error">
        <div class="card-dk">
            <h4 class="card-header" *ngIf="errorTitle">{{errorTitle}}</h4>
            <div class="card-body">
                <div class="alert alert-danger mt-3 mb-0">{{error}}</div>

                <br>
            
                <div *ngIf="showDismissButton">
                    <button class="btn btn-warning" (click)="error=null">Dismiss</button>
                </div>
                

            </div>
        </div>
    </div>
</div>



<div id="softErrorBox" [hidden]="hideSoftErrorBox || useSnackBarErrors" class="alc_update_div" style="width:70%;">
    
    
    
    <label #softErrorBoxLabel style="color: white;">
        <div>Something went wrong.</div>
        {{error}}
    </label>
    
    <div *ngIf="showDismissButton">
        <button class="btn btn-warning" (click)="error=null">Dismiss</button>
    </div>
</div>

