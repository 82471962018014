
<h1 class="dialog-form-heading" *ngIf="(this.entities?.length> 0) && ((includePatientDetailsRequest ?? false) ===false)" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{userSeletInfo.prompt}}
  
</h1>


<mat-card>
  <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button>
  <mat-card-content>
 
 


      <div>
  

        <h1 class="dialog-form-heading" style="margin-bottom: 1em;" *ngIf="(includePatientSignContracts) || (includePatientDetailsRequest)"  mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
          Please select patient request options.

        </h1>

        
        <br *ngIf="(includePatientSignContracts) || (includePatientDetailsRequest)" >



        <!-- <b *ngIf="(includePatientSignContracts) || (includePatientDetailsRequest)" class="select-prompt">
          Please select patient request options.
          <br>
          <br>
        </b> -->
        <div *ngIf="(includePatientSignContracts) || (includePatientDetailsRequest)"
          class="patient-request-selections">
  
  
  
          <div *ngIf="includePatientDetailsRequest">
            <input type="checkbox" [(ngModel)]="requestPatientCompleteDetails">
            <label class="option-label">Patient must complete their details</label>
          </div>
  
          <div *ngIf="includePatientSignContracts">
            <input type="checkbox" [(ngModel)]="requestPatientSignContracts">
            <label class="option-label">Patient must agree to contracts</label>
          </div>
  
        </div>
  
        <br *ngIf="(includePatientSignContracts) || (includePatientDetailsRequest)" >

        <h1 class="dialog-form-heading" *ngIf="(this.entities?.length> 0) && ((includePatientDetailsRequest ?? false)===true)" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{userSeletInfo.prompt}}</h1>
        <!-- <b *ngIf="this.entities?.length> 0" class="select-prompt">          
          {{userSeletInfo.prompt}}
        </b> -->
  
        <br>
        <br>
  
  
  
        <ul id="myUL"
          style="background-color: ghostwhite; height:210px; overflow:hidden; overflow-y:scroll; list-style-type:none; "
          *ngIf="entities.length > 0" class="form-control">
  
  
        <li>
            <div *ngIf="moreThanOneCategory() === true" class="expander-buttons-container">
                <button class="btn btn-primary" (click)="expandOrCollapseAll()"  [hidden]="areCategoryCollapsed===false" style="outline: none;">
                   
                    <svg-icon src="assets/svgs/folder_open_black_24dp.svg" class="custom-svg-icon-orange"></svg-icon>
                    <label>Expand All</label>
                  </button>
                  
                    <button class="btn btn-primary" (click)="expandOrCollapseAll()"  [hidden]="areCategoryCollapsed===true" style="outline: none;">
                        <svg-icon src="assets/svgs/folder_white_24dp.svg" class="custom-svg-icon-orange"></svg-icon>
                        <label>Collapse All</label>
                    </button>
              </div>
        </li>

          <li>
            <div *ngIf="makeNoSelectionOptionAvailable">
              <button (click)="selectNone()" class="btn btn-primary btn-spacing">None</button>
            </div>
          </li>
  
          <ng-container *ngFor="let cat of getCategorise(); let i = index">
            <li style="text-align: left; min-width:10cm!important;" value="{{cat}}">
              <span (click)="toggleCategory(cat)">
                
                
                
                <svg-icon src="assets/svgs/{{isCategoryExpanded(cat) ? 'folder_open' : 'folder'}}_black_24dp.svg" class="custom-svg-icon-orange"></svg-icon>
                
                <b> {{cat}} </b>
              </span>
  
              <ul class="nested" style="list-style-type:none" *ngIf="isCategoryExpanded(cat)">
                <li *ngFor="let ent of entitiesOfCategoryOfCategory(cat); let j = index" value="{{ent.id}}"
                  [class.selected]="ent.id == selectedEntityId" [class]="isBillableForm(ent) ? 'billable-form' : ''"
                  (click)="selectEntity(ent)">
  
                  <span [class]="shouldGlow(ent) === true ? 'glowing-button' : ''">
                    <input type="checkbox" *ngIf="allowMultiSelect" [checked]="checkboxDictionary[ent.id]"
                      (change)="checkboxDictionary[ent.id] = !checkboxDictionary[ent.id]">
  
                    
                    <svg-icon class="tree-leaf" src="assets/svgs/{{matIconForEntity}}_black_24dp.svg" class="custom-svg-icon-green"></svg-icon>
                    


                    {{ent.name}} {{(isBillableForm(ent) === true) ? ' (Billable 💲)' : ''}}
  
                  </span>
  
  
                </li>
              </ul>
  
            </li>
          </ng-container>
  
  
  
        </ul>
  
  
        <div *ngIf="allowFiltering">
          <!-- (input)="filterEntities()" -->
          <input type="text" class="form-control" placeholder="Filter" [(ngModel)]="filterText" >
        </div>


        <div mat-dialog-actions *ngIf="allowMultiSelect">
  
          <button [disabled]="selectionsMade===false" style="margin: 2px;" class="btn btn-primary btn-spacing"
            (click)="submitForMultiselect()">Ok</button>
        </div>
  
  
      </div>
  
    </mat-card-content>
  </mat-card>

  
  
  