import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from './message-box.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageBox } from './message-box.provider';
import { MessageBoxRoutingModule } from './message-box-routing.module';
import { InLineAssistanceModule } from '@app/in-line-assistance/in-line-assistance.module';



@NgModule({
  declarations: [MessageBoxComponent],
  imports: [
    CommonModule,
    // BrowserModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    // BrowserAnimationsModule,
    MessageBoxRoutingModule,
    InLineAssistanceModule
  ], 
  exports: [MessageBoxComponent], 
  providers: [MessageBox]
})
export class MessageBoxModule { }


export enum Buttons {
  Ok,
  YesNo
}

export enum Button {
  Ok,
  Yes,
  No
}