import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinct, Subject } from 'rxjs';
import { SelectableItem } from './selectable-item';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss']
})
export class ItemSelectorComponent implements OnInit {

  public screenWidth = 0;
  public viewState  : "activeCodes"| "masterCodes" = "activeCodes";
  public masterRecords: SelectableItem[] = [];
  private _masterRecordsFilterBackup: SelectableItem[] = [];
  @Input() public set fromItems(items: SelectableItem[]) {
    this.masterRecords = items;
    this._masterRecordsFilterBackup = items;
  }

  public activatedRecords: SelectableItem[] = []
  private _activatedRecordsFilterBackup: SelectableItem[] = []
  @Input() public set toItems(items: SelectableItem[]) {
    this.activatedRecords = items;
    this._activatedRecordsFilterBackup = items;
  }

  @Input() public leftBlockLabel = '';
  @Input() public rightBlockLabel = '';
  @Input() public loading = true;

  @Output() public onActivate = new EventEmitter<SelectableItem[]>();
  @Output() public onDeactivate = new EventEmitter<SelectableItem[]>();
  @Output() public onEdit = new EventEmitter<SelectableItem>();
  @Output() public onCreateNew = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  public leftSearchTerm = '';
  public righSearchTerm = '';
  public searchEvent$ = new Subject<{ block: string, value }>();

  public get countActiveSelected() {
    return this.activatedRecords?.filter(x => x.selected)?.length ||  0;
  }

  public lastSelectedBlock: 'LEFT' | 'RIGHT' | null = null;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.searchEvent$.pipe(debounceTime(300), distinct()).subscribe(changes => {
      console.log("SEARCH ", changes);
      switch (changes.block) {
        case 'left': {
          this.filterMasterSet(changes.value);
          break
        }
        case 'right': {
          this.filterActivateCodes(changes.value);
          break
        }
      }
    })
  }

  private filterMasterSet(searchValue: string): void {
    if (this.leftSearchTerm.length > 0) {
      this.masterRecords = this._masterRecordsFilterBackup.filter(m => {
        return m.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          m.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      });
    } else {
      this.masterRecords = this._masterRecordsFilterBackup;
    }
  }

  private filterActivateCodes(searchValue: string): void {
    if (this.righSearchTerm.length > 0) {
      this.activatedRecords = this._activatedRecordsFilterBackup.filter(m => {
        return m.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          m.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      });
    } else {
      this.activatedRecords = this._activatedRecordsFilterBackup;
    }
  }

  public deactivate(): void {
    const markedForDeactivation = this.activatedRecords.filter(i => i.selected);
    this.masterRecords = [...markedForDeactivation, ...this.masterRecords];
    this.masterRecords.forEach(i => i.selected = false);

    this.activatedRecords = this.activatedRecords.filter(i => markedForDeactivation.map(x => x.code).indexOf(i.code) < 0);

    this.onDeactivate.emit(markedForDeactivation);
  }

  public activate(): void {
    const markedForDeactivation = this.masterRecords.filter(i => i.selected);
    this.activatedRecords = [...markedForDeactivation, ...this.activatedRecords];
    this.activatedRecords.forEach(i => i.selected = false);

    this.masterRecords = this.masterRecords.filter(i => markedForDeactivation.map(x => x.code).indexOf(i.code) < 0);

    this.onActivate.emit(markedForDeactivation);
  }

  public onRequestEdit() {
    this.onEdit.emit(this.activatedRecords.find(x => x.selected));
  }

  public onCreateRequest() {
    this.onCreateNew.emit();
  }
}
