import { Component, NgModule, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { BrowserModule } from '@angular/platform-browser';



// if (environment.production) {
enableProdMode();
// }

// Min supported Chromium version
const minChromiumVersion = 90;

// Min supported Safari version
const minSafariVersion = 11;

// Get the user-agent string
const userAgent: string = window.navigator.userAgent;

// Get the Chromium version
const chromiumVersion: number | false = getChromiumVersion(userAgent);
const safariVersion: number | false = getSafariVersion(userAgent);

// Check the Chromium version
// Check the Chromium version
platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));

// if (safariVersion && safariVersion < minSafariVersion) {
//   alert('Your version of safari browser is not supported. Please use a newer supported browser.');
// }
// else {
//   platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
 
// }



// Define a function to get the Chromium version number
function getChromiumVersion(userAgent: string): number | false {
  const chromiumAgent = userAgent.indexOf('Chrome') !== -1;
  if (chromiumAgent) {
    const chromiumVersion = parseFloat(
      userAgent.split('Chrome/')[1]
    );
    return chromiumVersion;
  }
  return false;
}


// Define a function to get the Safari version number
function getSafariVersion(userAgent: string): number | false {
  const safariAgent = userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Version') !== -1;
  if (safariAgent) {
    const safariVersion = parseFloat(
      userAgent.split('Version/')[1].split(' ')[0]
    );
    return safariVersion;
  }
  return false;
}

