import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICD10Code } from '@app/_alchemint/alchemint_dm';

@Component({
  selector: 'app-icd-code-form',
  templateUrl: './icd-code-form.component.html',
  styleUrls: ['./icd-code-form.component.scss']
})
export class IcdCodeFormComponent implements OnInit {

  public createMode = false;
  public icdCode: ICD10Code;
  constructor(public dialogRef: MatDialogRef<IcdCodeFormComponent>, @Inject(MAT_DIALOG_DATA) private data: ICD10Code) {
    this.icdCode = data ?  data :  new ICD10Code();
    if(!this.icdCode.id){
        this.createMode = true;
    }
  }

  ngOnInit(): void {
  }
}
