 
<div *ngIf="icdCode" class="form">
    <h4>ICD Code Form</h4>
    <mat-form-field appearance="fill">
        <mat-label>Id</mat-label>
        <input matInput [(ngModel)]="icdCode.id" [disabled]="!createMode">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="icdCode.name"  >
    </mat-form-field>
    <!-- <mat-form-field appearance="fill">
        <mat-label>Units</mat-label>
        <input matInput [(ngModel)]="icdCode.">
    </mat-form-field> -->
    <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea matInput [(ngModel)]="icdCode.description"></textarea>
    </mat-form-field>
    <div style="display: flex; justify-content: space-between;">
        <button mat-raised-button class="btn btn-primary" (click)="dialogRef.close(icdCode)"
            [disabled]="!icdCode.description"> Save</button> 
        <button mat-raised-button color="warn" (click)="dialogRef.close(null)"> Cancel</button>
    </div>
</div>