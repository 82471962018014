import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, firstValueFrom  } from 'rxjs';
import  *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { AlchemedPreLoadData, TemplateDocumentFeatureCode } from '@app/_alchemint/alchemint_composite_requests';
import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { Environment, EnvService } from './environment.service';
import { re } from 'mathjs';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { AuthenticationService } from './authentication.service';


@Injectable(
  { providedIn: 'root'}
)
export class SettingsService {
  

  public maxApiCallsPerSecond = 50;

  private _cacheOrgSettingWithDefaultsAndDescription : OrgSettingWithDefaultsAndDescription [] = null;
  public emailSignatureHtml : string = null;
  constructor(private apiInterfaceService : ApiInterfaceService, private webApi: WebApiInterfaceService,
    private envService : EnvService, 
    private authenticationService: AuthenticationService) { }

  getAllOrgSettingsWithDefaults () : OrgSettingWithDefaultsAndDescription []
  {
    var defs : OrgSettingWithDefaultsAndDescription [] = [];
    if (this._cacheOrgSettingWithDefaultsAndDescription == null)
    {
     for (let item in eWebAppOrgSettings)   {
       if (isNaN(Number(item)) == false) {
         const currSet: eWebAppOrgSettings = eWebAppOrgSettings[item  as keyof typeof eWebAppOrgSettings];
         let o = new OrgSettingWithDefaultsAndDescription();
         o.settingIdentifier = currSet;
         o.name = currSet.toString();
         o.value = this.getOrgSettingTypeDefault(item);
         o.type = this.getOrgSettingType(item);
         o.description = null;
         o.default = this.getOrgSettingTypeDefault(item);
         defs.push(o);
          
          
          
        //   {
        //   settingIdentifier : currSet, 
        //   name: currSet.toString(), value : this.getOrgSettingTypeDefault(item), 
        //   type : this.getOrgSettingType(item), 
        //   description : null, 
        //   default : this.getOrgSettingTypeDefault(item)
          
        // },
          
          
       }

     }

     this._cacheOrgSettingWithDefaultsAndDescription = defs.map (x=> {
       if (x.description == null)  
       {
         x.description = x.name.replace(/([A-Z])/g, ' $1').trim();

        if (x.settingIdentifier.toString() == eWebAppOrgSettings[eWebAppOrgSettings.UseIdNumberToDeduceDateOfBirth])
        {
          x.description += " and Sex";
        }

       }
       return x; }
       );

       this._cacheOrgSettingWithDefaultsAndDescription = defs;
    }

   return this._cacheOrgSettingWithDefaultsAndDescription; 

    // s = s.replace(/([A-Z])/g, ' $1').trim()



    //defs.push({name: "ExclusionFieldsFromPatientRequest", default : String(false), type : 'STRING', description : "Exclude Fields From Patient Request"});
    //defs.push({name: "OrgDiaryRefreshInterval", default : String(5), type : 'STRING', description : "Diary Refresh Interval"});

    // return defs;
  }

  _webAppOrgSettingsWithValuesCache : OrgSettingWithDefaultsAndDescription [] = null;

  public getWebAppOrgSettingsWithValues () : Observable<OrgSettingWithDefaultsAndDescription []>
  {
   
   return new Observable<OrgSettingWithDefaultsAndDescription []>
   (
     observer => {

       if (this._webAppOrgSettingsWithValuesCache == null)
       {
         var webAppOrgSettings = this.getAllOrgSettingsWithDefaults();
         var webAppOrgSettingsWithVals:  OrgSettingWithDefaultsAndDescription [] = [];
 
         this.apiInterfaceService.getPreLoadDataAndSetGlobals().subscribe(
           preloadData => 
           {
             
             webAppOrgSettings.forEach(x => {
 
               var settingWitVal = x;
               settingWitVal.value =this.getValueOrDefault(preloadData, webAppOrgSettings, x.name, settingWitVal.type);
               webAppOrgSettingsWithVals.push(settingWitVal);

               if (settingWitVal.name == eWebAppOrgSettings[eWebAppOrgSettings.MaximumApiCallsPerSecond])
               {
                 this.maxApiCallsPerSecond = settingWitVal.value;
               }
             }



             
             );
             this._webAppOrgSettingsWithValuesCache = webAppOrgSettingsWithVals;
             observer.next(this._webAppOrgSettingsWithValuesCache);
           }
         );
 
       }
       else
       {
         observer.next(this._webAppOrgSettingsWithValuesCache);
       }
   
     }
   )
  }

  public reloadWebAppOrgSettingsWithValuesCache () : Observable<OrgSettingWithDefaultsAndDescription []> 
  {
    return new Observable<OrgSettingWithDefaultsAndDescription []> 
    (
      observer => {
        this._webAppOrgSettingsWithValuesCache = null;
        this.getWebAppOrgSettingsWithValues().subscribe(
          settings=> { observer.next(settings); }
        );
      }
    );
  }

  private getValueOrDefault (preloadData : AlchemedPreLoadData, webAppSettings :OrgSettingWithDefaultsAndDescription [],  name : string, type : string) : any
  {

     if (preloadData?.orgSettings)
    {
      var found : Alchemint.OrgSetting [] = preloadData?.orgSettings.filter(x => x.settingName == name);
      if (found.length > 0)
      {
        var fnd = found[0];
        if (type == 'BOOL')
        {
          return (fnd.settingValue?.toLowerCase() == 'true');
        }
        else
        {
          return fnd.settingValue;
        }
      }
      else
      {
        var found2 = webAppSettings.filter(x => x.name == name);
        if (found2.length > 0)
        {
          if (type == 'BOOL')
          {
            return (found2[0].value == true);
          }
          else
          {
            return found2[0].value;
          }
        }
      }
    }
    return false;
  }


  public getSettingValue (setting : eWebAppOrgSettings) : any
  {
    if (this._cacheOrgSettingWithDefaultsAndDescription == null)
    {
      // console.warn(setting + ' requested but settings have not yet been cached;');
      return null
    }
    else
    {
      var val = this._cacheOrgSettingWithDefaultsAndDescription.find(x => x.settingIdentifier.toString() == eWebAppOrgSettings[setting].toString());
      if (val)
      {
        return val.value;
      }
      else
      {
        return null;
        // throw 'Setting not found ' + eWebAppOrgSettings.toString();
      }
    }
  }

  saveWebAppOrgSettings (settingsForUpdate : Alchemint.OrgSetting []) : void
  {
    if (settingsForUpdate?.length > 0)
    {
      this.apiInterfaceService.updateOrgSettingsWebGuiApiCall(settingsForUpdate).subscribe(
        ret => {
          this.reloadWebAppOrgSettingsWithValuesCache();
        }, 
        err => { throw err;}
      );
    }
  }

  getOrgSettingType (setting : string) : string
  {
    
    if (setting=== eWebAppOrgSettings.maxfilesize.toString())
    {
      return 'DECIMAL';
    }
    else if (setting=== eWebAppOrgSettings.MaximumApiCallsPerSecond.toString())
    {
      return 'DECIMAL';
    }
    else if (setting=== eWebAppOrgSettings.ImageResizePercentage.toString())
    {
      return 'DECIMAL';
    }

    else if (eWebAppOrgSettings[setting].startsWith("FieldAlias"))
    {
      return 'STRING';
    }
    else if (setting=== eWebAppOrgSettings.FieldCustomizer.toString())
    {
      return 'STRINGLONG';
    }
    else if (setting=== eWebAppOrgSettings.EmailingOption.toString())
    {
      return 'DECIMAL';
    }
    else if (setting=== eWebAppOrgSettings.UseHtmlClinicalNotes.toString())
    {
      return 'BOOL';
    }
    else if (setting=== eWebAppOrgSettings.UsePatientSummariesForOrg.toString())
    {
      return 'BOOL';
    }
    else if (setting=== eWebAppOrgSettings.DefaultToAllCliniciansSelectedInDiarry.toString())
    {
      return 'BOOL';
    }
    else if (setting=== eWebAppOrgSettings.MaximumFormImageFieldSize.toString())
    {
      return 'DECIMAL';
    }
    else if (setting=== eWebAppOrgSettings.UseRangesForLargeFiles.toString())
    {
      return 'BOOL';
    }
    else if (setting=== eWebAppOrgSettings.HideWeekends.toString())
    {
      return 'BOOL';
    }
    else if (setting=== eWebAppOrgSettings.DiaryStartHour.toString())
    {
      return 'DECIMAL';
    }
   else
    {
      return 'BOOL';
    }
  }

  getOrgSettingMax (setting : string) : number
  {
    
    if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.maxfilesize].toString())
    {
      return 8000000;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.MaximumApiCallsPerSecond].toString())
    {
      return 50;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.ImageResizePercentage].toString())
    {
      return 90;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.MaximumFormImageFieldSize].toString())
    {
      return 1000000;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.DiaryStartHour].toString())
    {
      return 9;
    }
    else
    {
      return 10000000;
    }
  }

  getOrgSettingMin (setting : string) : number
  {
    
    if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.maxfilesize].toString())
    {
      return 1000000;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.MaximumApiCallsPerSecond].toString())
    {
      return 5;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.MaximumFormImageFieldSize].toString())
    {
      return 100;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.ImageResizePercentage].toString().toString())
    {
      return 10;
    }
    else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.DiaryStartHour].toString())
    {
      return 5;
    }
    else
    {
      return 1000000;
    }
  }

  getOrgSettingTypeDefault (setting : string) : any
  {
    if (this.getOrgSettingType(setting) == 'DECIMAL')
    {
      
      if (setting=== eWebAppOrgSettings.maxfilesize.toString())
      {
        return 4000000;
      }
      else if (setting=== eWebAppOrgSettings.MaximumApiCallsPerSecond.toString())
      {
        return 30;
      }
  
      else if (setting=== eWebAppOrgSettings.MaximumFormImageFieldSize.toString())
      {
        return 1000000;
      }
      else if (setting=== eWebAppOrgSettings.ImageResizePercentage.toString())
      {
        return 100;
      }
      else if (setting=== eWebAppOrgSettings[eWebAppOrgSettings.DiaryStartHour].toString())
      {
        return 5;        
      }
      else
      {
        return null;
      }
      
    }
    else if (this.getOrgSettingType(setting) == 'STRING')
    {
      return null;
    }
    else if (this.getOrgSettingType(setting) == 'STRINGLONG')
    {
      return null;
    }
    else
    {
      return false;
    }
  }


  public getLocalSetting (setting : eWebAppLocalSettings, type : string, defaultValue : any) : boolean
  {
    var value : any =  localStorage.getItem(eWebAppLocalSettings[setting].toString());
    if (value)
    {
      if (type === 'BOOL')
      {
        return (value?.toLowerCase() === 'true');
      }
      else
      {
        return value;
      }
    }
    else
    {
      return defaultValue;
    }
    
  }


  getLocalSettingMax (setting : string) : number
  {
    
    if (setting=== eWebAppLocalSettings[eWebAppLocalSettings.ClinicalNoteEditorRows].toString().toUpperCase())
    {
      return 200;
    }
    else if (setting=== eWebAppLocalSettings[eWebAppLocalSettings.CheckForUpdateIntervalHours].toString().toUpperCase())
    {
      return 120;
    }
    else
    {
      return 100000;
    }
  }

  getLocalSettingMin (setting : string) : number
  {
    
    if (setting=== eWebAppLocalSettings[eWebAppLocalSettings.ClinicalNoteEditorRows].toString().toUpperCase())
    {
      return 5;
    }
    if (setting=== eWebAppLocalSettings[eWebAppLocalSettings.CheckForUpdateIntervalHours].toString().toUpperCase())
    {
      return 1;
    }
    else
    {
      return 5;
    }
  }

  public setLocalSetting (setting : eWebAppLocalSettings, value : boolean) 
  {
    localStorage.setItem(eWebAppLocalSettings[setting].toString(), (value ?? false).toString());
    return value;
  }

  public getWebAppLocalSettingsWithValues () : LocalSetting []
  {
    var settings : LocalSetting [] = [];

    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.ShowDeveloperTools, 'BOOL', false));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.UseLegacyHtmlEditing, 'BOOL', false));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.UseApiKeyFile, 'BOOL', false));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.ClinicalNoteEditorRows, 'INT', 30));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.EnableBetaFeatures, 'BOOL', false));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.BlurMode, 'BOOL', false));
    settings.push(this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.CheckForUpdateIntervalHours, 'INT', 48));

    return settings;
  }

  public getWebAppLocalSettingWithValues (settingType : eWebAppLocalSettings, type: string, defaultValue : any) : LocalSetting 
  {
    var set = new LocalSetting ();
    set.description = this.buildWebAppSettingDescription(settingType); 
    set.toolTip = this.buildWebAppSettingTip(settingType);

    set.value = this.getLocalSetting(settingType, type, defaultValue);
    set.type = type;
    set.settingIdentifier = settingType;
    return set;
  }

  private buildWebAppSettingDescription (settingType : eWebAppLocalSettings) : string
  {
    return eWebAppLocalSettings[settingType].replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private buildWebAppSettingTip (settingType : eWebAppLocalSettings) : string
  {
    return null;
  }

  public get showDeveloperOptions () : boolean 
  {
    return this.getLocalSetting(eWebAppLocalSettings.ShowDeveloperTools, 'BOOL', false);
  }

  public get useApiKeyFile () : boolean 
  {
    return this.getLocalSetting(eWebAppLocalSettings.UseApiKeyFile, 'BOOL', false);
  }

  public get useLegacyHtmlEditing () : boolean 
  {
    return this.getLocalSetting(eWebAppLocalSettings.UseLegacyHtmlEditing, 'BOOL', false);
  }

  public useOrgSmtpSelected(): boolean {
    var emailOption = this.getSettingValue(eWebAppOrgSettings.EmailingOption);
    return (emailOption === '1');

  }

  public getEditorRowsSettingValue () : number 
  {
    var set = this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.ClinicalNoteEditorRows, 'INT', 30);
    return <number> set.value;
  }

  public getCheckForUpdateIntervalHours () : number 
  {
    var set = this.getWebAppLocalSettingWithValues(eWebAppLocalSettings.CheckForUpdateIntervalHours, 'INT', 24);
    return <number> set.value;
  }

  public get useBlurMode () : boolean 
  {
    return this.getLocalSetting(eWebAppLocalSettings.BlurMode, 'BOOL', false);
  }

  public getMaximumImageFieldSize(): number
  {
    var maxSize = this.getSettingValue(eWebAppOrgSettings.MaximumFormImageFieldSize);
    var ret: number;
    if (maxSize)
    {
      if (isNaN(maxSize))
      {
        
        ret = + this.getOrgSettingTypeDefault(eWebAppOrgSettings.MaximumFormImageFieldSize.toString());
      }
      else
      {
        ret = + maxSize;
      }
    }
    else
    {
      ret = + this.getOrgSettingTypeDefault(eWebAppOrgSettings.MaximumFormImageFieldSize.toString());
    }

    if (ret < 100)
    {
      ret = 100;
    }

    return ret;
  }


  private getEmailSibnatureArtifactId(preLoadData: AlchemedPreLoadData): string
  {

    var customArtifactId = this.getUsersCustomConfiguredEmailSignatureArtifactId(preLoadData);

    if (customArtifactId)
    {
      return customArtifactId;
    }
    else
    {
      var filtered = preLoadData?.featureMaps?.filter(x => x.code === TemplateDocumentFeatureCode[TemplateDocumentFeatureCode.EmailSignature]);
      if (filtered?.length > 0)
      {
        return filtered[0]?.artifactId;
      }
      else
      {
        return null;
      }
    }
  }

  private getUsersCustomConfiguredEmailSignatureArtifactId(preLoadData: AlchemedPreLoadData)
  {
    var currUser = this.authenticationService.userIdFromToken;
    var userConfigRec : Alchemint.UserCustomConfiguration = preLoadData?.userCustomConfigurations?.find(x => x.loginId === currUser);

    if (userConfigRec)
    {
      return userConfigRec.emailSigTemplateArtifactId ?? null;
    }
    else
    {
      return null;
    }
  }


  public async getOrgEmailSignatureHtml(preLoadData: AlchemedPreLoadData): Promise<string>
  {
    if (this.emailSignatureHtml)
    {
      return this.emailSignatureHtml;
    }
    else 
    {
      var emailSignatureArtifactId = this.getEmailSibnatureArtifactId(preLoadData);
      if (emailSignatureArtifactId)
      {
        var artifactZipped: ArrayBuffer = await firstValueFrom(this.webApi.getArtifactFile(emailSignatureArtifactId));
        var unzipped: ArrayBuffer = await this.webApi.unzipArrayBufffer(artifactZipped);
        this.emailSignatureHtml =  this.convertArrayBufferToText(unzipped);
        return this.emailSignatureHtml;
      }    
      else
      {
        return null;
      }
  
    }
  }

  private convertArrayBufferToText(arrayBuffer: ArrayBuffer): string {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(arrayBuffer);
  }

}



export enum eWebAppOrgSettings 
{
  UseMarkDownForClinicalNotes,
  UseMarkDownForMDFiles,
  UseMarkDownForTextFiles,
  PreviewAndEditMarkDownTogether,
  DefaultToEditMarkDownMode,
  UseIdNumberToDeduceDateOfBirth,
  UseTheatreSlate,
  UseSmartForms,
  DefaultViewPatientDashboard, 
  FieldCustomizer,
  UsePhysicianDashBoard,
  ImageResizePercentage,
  
  
  // Do not change the case of this as it breaks implementation in the Windows Client
  maxfilesize,

  EmailingOption, 
  StoreOutgoingEmails,
  DontPromptAddToDiaryOnPatientAdd, 
  PromptAddToInpatientsOnPatientAdd, 
  MakeInpatientDiaryTheDefaultDiary, 
  FieldAliasBiographicalDetailsOther, 
  FieldAliasBiographicalDetailsOther2, 
  FieldAliasAdmissionOther, 
  FieldAliasAdmissionOther2,
  CentrePdfImages,
  UseAdvancedNotesModuleForClinicalNotes,
  UseHtmlClinicalNotes,
  SendOutAppointmentReminders,
  AutoParagraphAIDictatedNotes, 
  UsePatientSummariesForOrg,
  DefaultToAllCliniciansSelectedInDiarry,
  MaximumFormImageFieldSize,
  FieldAliasPatientOther, 
  FieldAliasPatientOther2, 
  UseRangesForLargeFiles,
  UseSMSForSecureFileSharing,
  HideWeekends,
  DiaryStartHour,
  MaximumApiCallsPerSecond
}


export enum eWebAppLocalSettings 
{
  ShowDeveloperTools,
  UseApiKeyFile,
  ClinicalNoteEditorRows, 
  UseLegacyHtmlEditing,
  EnableBetaFeatures, 
  BlurMode,
  ShowDemoDataTools,
  CheckForUpdateIntervalHours
}

export class OrgSettingWithDefaultsAndDescription 
{
  settingIdentifier : eWebAppOrgSettings
  name : string; 
  value : any;
  type : string;
  description : string;
  default : any;
  
  public get settingKey (): string
  {
    return <string> this.settingIdentifier?.toString().toUpperCase();
    //return eWebAppOrgSettings[this.settingIdentifier].toString();
  }
}

export class LocalSetting 
{
  settingIdentifier : eWebAppLocalSettings;
  get settingKey () : string
  {
    return eWebAppLocalSettings[this.settingIdentifier].toString().toUpperCase();
  }
  value : any;
  type : string;
  description : string;
  default : any;
  toolTip : string;
}

export const CLINICAL_NOTES_FILE_NAME : string = 'clinical notes.txt';