import { ActivatedRoute } from "@angular/router";
import { IAlchemintEntity } from "@app/_alchemint/alchemint_dm";
import { ApiInterfaceService } from "@app/_services/alchemint.apiinterface.service";
import { WebApiInterfaceService } from "@app/_services/alchemint.webapiinterface.service";
import { Observable } from "rxjs";

export  class UiHelper 
{
    public static  camelCaseToReadable (text : string) : string{
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    public static readableComponentName (compName : string) : string
    {
    //   const excludeComponents : string [] = ['ControlPanelComponent', 'LoginComponent'];  

      const includeComponents : string [] = ['InpatientsComponent', 'SettingsComponent'];  
    //   const aliasComponents : string [] = ['ControlPanelComponent', 'LoginComponent'];  

      let aliasComponents: {[key: string]: string} = {
        MyDiaryComponent: 'Diary',
        Other: 'Other'
        }


      if (compName)
      {
        if (includeComponents.includes(compName) === false)
        {
            return '';
        }
        else
        {
            if (aliasComponents[compName])
            {
                return aliasComponents[compName];
            }
            else
            {
                return this.camelCaseToReadable(compName)?.replace('Component','');
            }
        }
        
      }
      else
      {
        return null;
      }
    }

    public static observeRouteParamater (act : ActivatedRoute, paramName : string ) : Observable<string>
    {


      return new Observable<string> (
        observer => {
          act.params.subscribe(params => {
            if (params[paramName]) {
              observer.next(params[paramName]);
              observer.complete();
            }
            else
            {
              observer.next(null);
              observer.complete();
            }
          }, 
          err => { 
            observer.error(err);
            observer.complete();
          }
          );
        }
      );
    }
  

    public static observeQueryParamater (act : ActivatedRoute, paramName : string ) : Observable<string>
    {


      return new Observable<string> (
        observer => {
          act.queryParams.subscribe(params => {
            if (params[paramName]) {
              observer.next(params[paramName]);
              observer.complete();
            }
            else
            {
              observer.next(null);
              observer.complete();
            }
          }, 
          err => { 
            observer.error(err);
            observer.complete();
          }
          );
        }
      );
    }


  public static getEntityFromRouteParamater <T> (emptyEntity : IAlchemintEntity, webApiInterfaceService : WebApiInterfaceService, act : ActivatedRoute, idRouteParamName : string) : Observable<T>
  {

    return new Observable<T> (
      observer => {
        this.observeRouteParamater(act, idRouteParamName).subscribe(
          id => {
            if (id)
            {
              webApiInterfaceService.getEntity<T> (emptyEntity, id).subscribe(
                entity => {
                  observer.next(entity);
                  observer.complete();
                }, 
                error => {
                  observer.error(error);
                  observer.complete();
                }
              );
            }
            else
            {
              observer.next(null);
              observer.complete();
            }
          }, 
          err => { 
            observer.error(err);
            observer.complete();
          }
        );
      }
      
    );



  }

  public static getEntityFromRouteQueryParamater <T> (emptyEntity : IAlchemintEntity, webApiInterfaceService : WebApiInterfaceService, act : ActivatedRoute, idRouteParamName : string) : Observable<T>
  {

    return new Observable<T> (
      observer => {
        this.observeQueryParamater(act, idRouteParamName).subscribe(
          id => {
            if (id)
            {
              webApiInterfaceService.getEntity<T> (emptyEntity, id).subscribe(
                entity => {
                  observer.next(entity);
                  observer.complete();
                }, 
                error => {
                  observer.error(error);
                  observer.complete();
                }
              );
            }
            else
            {
              observer.next(null);
              observer.complete();
            }
          }, 
          err => { 
            observer.error(err);
            observer.complete();
          }
        );
      }
      
    );



  }
  public static areAllFilesSafeToUpload(filesArray: File[]) : boolean
  {
    const extensions = filesArray.map(f => f.name.split('.').pop());
    if (extensions.some(e => e === 'exe')) {
      return false;
    }
    else
    {
      return true;
    }
  }
  

  }


