import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { DeployedEnvironment, EnvService } from '@app/_services/environment.service'


// import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '@app/_services/settings-service.service';

@Injectable({
    providedIn: 'root'
  }
)
export class JwtInterceptor implements HttpInterceptor {

    private environment : DeployedEnvironment;

    private forcedLogout : boolean = false;

    private errorSubject = new Subject<void>();
    //private error$ = this.unreachableErrorSubject.asObservable().pipe(debounceTime(500)); 

    constructor(private authenticationService: AuthenticationService, private environmentService : EnvService, 
        private activatedRoute: ActivatedRoute, 
        private rateLimiterService: RateLimiterService, private settingsService: SettingsService) { 
        this.environment = environmentService.deploymentSettings 
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (request.url.includes(':5041/')) {
            // Clone the request without the custom header to avoid side effects
            //const newRequest = request.clone({ headers: request.headers.delete('SkipInterceptor') });
            return next.handle(request);
        }

        if (this.authenticationService.useNewAuthenticationMethod)
        {
            return this.interceptNew(request, next);    
        }
        else
        {
            return this.interceptOld(request, next);    
        }
        
    }

    private excludedUrls: string[] = [
        '/supportdesk/verifyuserandpaswsword'        
    ];

    interceptNew(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        
        const isExcluded = this.excludedUrls.some(url => request.url.includes(url));
        if (isExcluded) {
            // If excluded, proceed without modifying the request
            return next.handle(request);
        }

        const clientId = `XXX`;
        
        if (request.url.startsWith(`assets/`) === false)
        {
            if (this.rateLimiterService.shouldLimit(clientId, this.settingsService.maxApiCallsPerSecond)) {
                console.log('Rate limit exceeded');
                return throwError(new Error('Rate limit exceeded'));
            }
        }
        else
        {
            //alert(request.url);
        }
        
      

        var apiKey: string; 
        const user = this.authenticationService.userValue;
        
        if (this.authenticationService.apiKeyValue)
        {
            apiKey = this.authenticationService.apiKeyValue;
        }
        else
        {
            apiKey = this.authenticationService.getStoredApiKey();
        }
        

        var isLoggedIn = user && user.token;
        // var isLoggedIn = this.authenticationService.storedBearerToken;
        const isApiUrl = request.url.startsWith(this.environment.apiUrl);
        var isAuthUrl = request.url.startsWith(this.environment.apiAuthUrl);
        const isRevokeUrl = request.url.endsWith('revoke-token');
        const withCreds = true;
        const isPublicContent = (request.url.indexOf(this.environment.publicContentEndPoint) > 0);
        const isStatsContent = (request.url.indexOf(this.environment.apiStatsUrl) > 0);
        const isExternalWebCall = (request.url.indexOf(this.environment.apiWebUiUrl) > -1);
        const isExtendedApiUrl  = (request.url.indexOf(this.environment.apiExtendedUrl) > -1);
        const isBiApiUrl  = (request.url.indexOf(this.environment.apiBiUrl) > -1);

        var alcAuthToken : string = '';
        var heads: {[k: string]: any} = {};

        if (this.authenticationService.integratedLoggedIn)
        {
            alcAuthToken = this.authenticationService.integratedBearerToken;
            apiKey = this.authenticationService.integratedApiKey;
            isLoggedIn = "true";
            heads.Authorization = `Bearer ${alcAuthToken}`;
        }
        else if (this.authenticationService.storedBearerToken && ((isAuthUrl == false) || (isRevokeUrl == true)))
        {
            alcAuthToken = this.authenticationService.storedBearerToken;
            heads.Authorization = `Bearer ${alcAuthToken}`;
        }

        heads.ALClientIdentifier ="AlcSpa";
        heads.ALClientVersion = "AlcS1.0";

        var activatedRoute = 'xx'; 
        activatedRoute = window.location.href;
        if (((activatedRoute.includes('/helpdesk')) && (request.url.includes('supportdesk'))) || ((activatedRoute.includes('backoffice'))))
        {
            // this.authenticationService.helpDeskApiKey
            if (this.authenticationService.helpDeskApiKey)
            {
                heads.ApiKey = this.authenticationService.helpDeskApiKey;
            }
            else
            {
                heads.ApiKey = this.authenticationService.backofficeApiKey;
            }
            
        }
        else if (request.url.includes('backoffice'))
        {
            heads.ApiKey = this.authenticationService.backofficeApiKey;
        }
        else if (apiKey)
        {
            heads.ApiKey = apiKey;
        }


        if (isPublicContent)
        {
            request = request.clone({withCredentials : withCreds});
        }
        else if (request.url.indexOf(this.environment.artifactServiceEndPoint) > 0)
        {
          if (request.url.indexOf("withfile"))
          {
            request = request.clone({
              setHeaders: {
                //Accept: 'application/json',
                Authorization: `Bearer ${alcAuthToken}` ,
                ApiKey : apiKey
              }, withCredentials : withCreds
            });
          }
          else
          {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
          }

        }
        else if (isLoggedIn && (isApiUrl || isRevokeUrl)) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
        }
        else if (isApiUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }
        else if (isLoggedIn && isAuthUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }
        else if (isAuthUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }        
        else if (isExtendedApiUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
        }
        else if (isStatsContent)
        {
            request = request.clone({
                setHeaders: { Accept: 'application/json' }, withCredentials : withCreds });
        }
        else if (isExternalWebCall)
        {
            request = request.clone({withCredentials : withCreds});
        }
        else if (isBiApiUrl)
        {
            //request.headers.append('ApiKey', apiKey);
            //request = request.clone({withCredentials : withCreds});
            //request = request.clone();
         
        }
        else
        {
            request = request.clone({
                setHeaders: { Accept: 'application/json' }, withCredentials : withCreds });

        }
     
        return next.handle(request);
    }
    
    interceptOld(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const user = this.authenticationService.userValue;
        var apiKey = this.authenticationService.apiKeyValue;
        var isLoggedIn = user && user.token;
        const isApiUrl = request.url.startsWith(this.environment.apiUrl);
        var isAuthUrl = request.url.startsWith(this.environment.apiAuthUrl);
        const isRevokeUrl = request.url.endsWith('revoke-token');
        const withCreds = true;
        const isPublicContent = (request.url.indexOf(this.environment.publicContentEndPoint) > 0);
        const isStatsContent = (request.url.indexOf(this.environment.apiStatsUrl) > 0);
        const isExternalWebCall = (request.url.indexOf(this.environment.apiWebUiUrl) > -1);
        const isExtendedApiUrl  = (request.url.indexOf(this.environment.apiExtendedUrl) > -1);
        const isBiApiUrl  = (request.url.indexOf(this.environment.apiBiUrl) > -1);

        let testapiWebUiUrl : string = this.environment.apiWebUiUrl;

        var alcAuthToken : string = '';
        var heads: {[k: string]: any} = {};

        if (this.authenticationService.integratedLoggedIn)
        {
            alcAuthToken = this.authenticationService.integratedBearerToken;
            apiKey = this.authenticationService.integratedApiKey;
            isLoggedIn = "true";
            heads.Authorization = `Bearer ${alcAuthToken}`;
        }
        else if (user && ((isAuthUrl == false) || (isRevokeUrl == true)))
        {
            alcAuthToken = this.authenticationService.storedBearerToken;
            heads.Authorization = `Bearer ${alcAuthToken}`;
        }

        heads.ALClientIdentifier ="AlcSpa";
        heads.ALClientVersion = "AlcS1.0";

        var activatedRoute = 'xx'; 
        activatedRoute = window.location.href;
        if ((activatedRoute.includes('/helpdesk')) && (request.url.includes('supportdesk')))
        {
            // this.authenticationService.helpDeskApiKey
            heads.ApiKey = this.authenticationService.helpDeskApiKey;
        }
        else if (apiKey)
        {
            heads.ApiKey = apiKey;
        }


        if (isPublicContent)
        {
            request = request.clone({withCredentials : withCreds});
        }
        else if (request.url.indexOf(this.environment.artifactServiceEndPoint) > 0)
        {
          if (request.url.indexOf("withfile"))
          {
            request = request.clone({
              setHeaders: {
                //Accept: 'application/json',
                Authorization: `Bearer ${alcAuthToken}` ,
                ApiKey : apiKey
              }, withCredentials : withCreds
            });
          }
          else
          {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
          }

        }
        else if (isLoggedIn && (isApiUrl || isRevokeUrl)) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
        }
        else if (isApiUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }
        else if (isLoggedIn && isAuthUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }
        else if (isAuthUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });        
        }        
        else if (isExtendedApiUrl) {
            request = request.clone({ setHeaders: heads, withCredentials : withCreds });
        }
        else if (isStatsContent)
        {
            request = request.clone({
                setHeaders: { Accept: 'application/json' }, withCredentials : withCreds });
        }
        else if (isExternalWebCall)
        {
            request = request.clone({withCredentials : withCreds});
        }
        else if (isBiApiUrl)
        {
            //request.headers.append('ApiKey', apiKey);
            //request = request.clone({withCredentials : withCreds});
            //request = request.clone();
         
        }
        else
        {
            request = request.clone({
                setHeaders: { Accept: 'application/json' }, withCredentials : withCreds });

        }
     
        return next.handle(request);
    }

    
}





// rate-limiter.service.ts


@Injectable({
  providedIn: 'root'
})
export class RateLimiterService {
  private requests: Map<string, number[]> = new Map();

  constructor() { }

  shouldLimit(clientId: string, maxApiCallsPerSecond: number): boolean {
    const now = Date.now();
    const windowTime = 1000; 1 //second ////60000; // 60 seconds
    const maxRequests = maxApiCallsPerSecond; // max requests per window

    if (!this.requests.has(clientId)) {
      this.requests.set(clientId, []);
    }

    const timestamps = this.requests.get(clientId).filter(timestamp => now - timestamp < windowTime);
    timestamps.push(now);
    this.requests.set(clientId, timestamps);

    return timestamps.length > maxRequests;
  }
}
