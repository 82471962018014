import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[blurElement]'
})
export class BlurElementDirective implements OnChanges {
  @Input() blurOn: boolean;
  @Input() blurAmount: string = '5px';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['blurOn'] && this.blurOn) {
      this.renderer.setStyle(this.el.nativeElement, '-webkit-filter', `blur(${this.blurAmount})`);
      this.renderer.setStyle(this.el.nativeElement, 'filter', `blur(${this.blurAmount})`);
    } else {
      this.renderer.removeStyle(this.el.nativeElement, '-webkit-filter');
      this.renderer.removeStyle(this.el.nativeElement, 'filter');
    }
  }
}
