<form [formGroup]="mfaRecoveryForm" (ngSubmit)="onSubmit()">
  <div class="form-title">
    <h4>Please provide recovery email and phone number</h4>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Recovery Email</mat-label>
    <input matInput formControlName="recoveryEmail"  placeholder="Enter your recovery email" type="email" required>
    <mat-error>Email is required for MFA</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Phone Number</mat-label>
    <input matInput formControlName="recoveryPhoneNumber"  placeholder="Enter your phone number" type="tel" required>
    <mat-error>Phone number is required for MFA</mat-error>
    <mat-error *ngIf="mfaRecoveryForm.controls['recoveryPhoneNumber'].invalid && mfaRecoveryForm.controls['recoveryPhoneNumber'].touched">
      Invalid phone number
    </mat-error>
  </mat-form-field>

  <button mat-raised-button class="btn-primary" [disabled]="mfaRecoveryForm.invalid"  type="submit">Submit</button>

  <button mat-raised-button type="button" (click)="activeDialog.close()">Cancel</button>

</form>
